<template>
    <b-dropdown v-bind="$attrs" variant="outline-secondary secondary-border-light" class="width-40 mr-2"
        v-if="actions && actions.length > 0">
        <b-dropdown-item v-for="(action, actionKey) in actions" :key="'action' + actionKey" @click="action.callback">
            <i :class="action.class" v-if="action.class"></i> {{ action.text.toUpper() }}
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
export default {
    name: "HeaderActionButton",
    props: {
        actions: {
            type: Array,
            default: () => []
        }
    }
}
</script>
<style lang="scss" scoped></style>
