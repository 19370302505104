<template>
    <div>
        <multiselect
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            label="text"
            track-by="value"
            :placeholder="$t('select')"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :disabled="disabled"
            @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <b-button variant="link" size="xs" v-if="selectAllBtn && !unselect" @click="selectAll">{{$t('select_all')}}</b-button>
        <b-button variant="link" size="xs" v-if="selectAllBtn && unselect" @click="unselectAll">{{$t('unselect_all')}}</b-button>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    // Helpers
    import translateNSelected from '@/helpers/translateNSelected';
    import handleInput from '@/helpers/handleInput';
    import setSelected from '@/helpers/setSelected';

    // Services
    import FacultyService from '@/services/FacultyService';
    import qs from 'qs'

    export default {
        props: {
            value: {
                default: null
            },
            level: {
                type: String,
                default: null
            },
            validateError: {
                type: String,
                default: ''
            },
            multiple: {
                type: Boolean,
                default: false
            },
            public: {
                type: Boolean,
                default: false
            },
            selectAllBtn: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = this.setSelected(newValue, this.options, this.multiple)
            }
        },
        data() {
            return {
                selected: null,
                options: [],
                unselect: false
            };
        },
        created() {
            this.getOptions();
        },
        methods: {
            translateNSelected: translateNSelected,
            handleInput: handleInput,
            setSelected: setSelected,
            getOptions() {

                const key = 'faculties_'+(this.public?'public':'not_public');

                let local = this.getWithExpiry(key);
                if(local){
                    this.setOptions(local);
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                    return;
                }

                const config = {
                    params: {
                        filter: {
                            level: this.level
                        },
                        sort: 'code',
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                this.options = [];
                if (this.public) {
                    FacultyService.getAllPublic(config)
                                  .then((response) => {
                                      const data = response.data.data;
                                      this.setWithExpiry(key, data)
                                      this.setOptions(data);
                                  })
                                  .then(() => {
                                      this.selected = this.setSelected(this.value, this.options, this.multiple)
                                  })
                }
                else {
                    FacultyService.getAll(config)
                                  .then((response) => {
                                      const data = response.data.data;
                                      this.setWithExpiry(key, data)
                                      this.setOptions(data);
                                  })
                                  .then(() => {
                                      this.selected = this.setSelected(this.value, this.options, this.multiple)
                                  })
                }

            },
            setOptions(data){
                data.map((item) => {
                    this.options.push({
                        value: item.code,
                        text: item.code + ' - ' + this.getLocaleText(item, 'name')
                    })
                })
            },
            selectAll(){
                this.unselect = true
                this.selected = []
                this.options.forEach(itm => {
                    this.selected.push(itm.value)
                })
                this.$emit('input', this.selected)
            },

            unselectAll(){
                this.unselect = false
                this.selected = null
                this.$emit('input', this.selected)
            },

            getItem(id){
                let found=null
                this.options.forEach((item)=>{
                    if(item.value===id){
                        found = item;
                    }
                });
                return found;
            }
        }
    }
</script>
