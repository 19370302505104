import Vue from 'vue';
import axios from 'axios';
import NProgress from 'nprogress';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Router from '@/plugins/Router'
import i18n from '@/plugins/i18n';
import {EventBus} from '@/main'
import store from '@/plugins/Store'
import sessionHook from "@/helpers/sessionHook"

const authApi = axios.create({
    baseURL: process.env.VUE_APP_ENDPOINT_AUTH,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': i18n.locale,
        'exposedHeaders': 'session-id',
    }
});

Vue.config.productionTip = false;
Vue.prototype.$http = authApi;

const activeCurrentButton =(value)=>{

    if(!!Vue.prototype.currentButton && Vue.prototype.currentButton.disable != null && !Vue.prototype.currentButton.disable)
    {
        Vue.prototype.currentButton.disable = value;
    }
    if(!!Vue.prototype.currentButton && Vue.prototype.currentButton.disable)
    {Vue.prototype.currentButton.disable = value;
        if(!value){
            Vue.prototype.currentButton = null
        }
    }
}

authApi.interceptors.request.use(
    config => {
        activeCurrentButton(true);
        NProgress.start();
        sessionHook.sessionTrackingRun()
        var token = localStorage.getItem('access_token');

        if (token) {
            config.headers.common['Authorization'] = token.replace(/^"+/, '').replace(/"+$/, '');
        }
        let pathname= window.location.pathname.split('/')
            .map(function (item){
                if(!isNaN(parseInt(item))){
                    return "*";
                }
                return item;
            }).join("/");
        config.headers.common['pathname'] = pathname;
        return config;
    },
    error => {
        activeCurrentButton(false);
        return Promise.reject(error);
    }
);
authApi.interceptors.response.use(
    response => {
        NProgress.done();

        activeCurrentButton(false);

        if (response.status === 200 || response.status === 201 || response.status === 204) {
            return Promise.resolve(response);
        }
        else {
            return Promise.reject(response);
        }
    },
    error => {
        activeCurrentButton(false);
        NProgress.done()
        if (error.response && error.response.status) {
            const message = error.response.data.message;
            switch (error.response.status) {
                case 400:
                    Vue.$toast.error(i18n.t('authApi.' + message))
                    break;
                case 401:
                    Router.push('/logout')
                    break;
                case 403:
                    Router.push('/403')
                    break;
                case 404:
                    Router.push('/404')
                    break;
                case 405:
                    Vue.$toast.error(i18n.t('authApi.' + message))
                    Router.push('/logout')
                    break;
                case 420:
                    let title = i18n.t('authApi.' + error.response.data.message);
                    let errors = error.response.data.errors;
                    EventBus.$emit('detailErrorsModalShow', {title: title, errors: errors});
                    break;
                case 427:
                    Vue.$toast.error(i18n.t('authApi.' + message))
                    store.dispatch("auth/logout");
                    break;
                case 429:
                    Vue.$toast.error(i18n.t('you_have_tried_too_many') + i18n.t('you_can_try_again_after_1_minute'));
                    break;
                case 500:
                    Vue.$toast.error(i18n.t('something_went_wrong'));
                    break;
                case 503:
                    Router.push('/503')
                    break;
            }
            return Promise.reject(error.response);
        }
    }
);

export default authApi;
