import Vue from 'vue'
import i18n from '@/plugins/i18n'

Vue.directive('uppercase', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress', function(e) {
            const regex = /[A-Za-zğüşiıöçĞÜŞİÖÇ ]/g;
            if (regex.test(e.key)) {
                el.value = el.value.toLocaleUpperCase(i18n.locale)
                setTimeout(function() {
                    el.value = el.value.toLocaleUpperCase(i18n.locale)
                }, 10, el);
            }
            else {
                e.preventDefault();
            }
        })
        el.addEventListener('keyup', () => {
            el.value = el.value.replace(/[^A-Za-zğüşiıöçĞÜŞİÖÇ, ]/g, '');
            el.value = el.value.toLocaleUpperCase(i18n.locale)
        })
    }
})

Vue.directive('onlyAlphaAndSpace', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress', function(e) {
            const regex = /[A-Za-zğüşiıöçĞÜŞİÖÇ ]/g;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
        })

        el.addEventListener('keyup', () => {
            el.value = el.value.replace(/[^A-Za-zğüşiıöçĞÜŞİÖÇ, ]/g, '');
        })
    }
})

Vue.directive('phoneNumber', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress', function(e) {
            const regex = /[0-9]/g;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
        })

        el.addEventListener('keyup', () => {
            el.value = el.value.replace(/[^0-9]/g, '');
            el.value = el.value.toUpper()
        })
    }
})

Vue.directive('number', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress', function(e) {
            const regex = /[0-9]/g;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
        })

        el.addEventListener('keyup', () => {
            el.value = el.value.replace(/[^0-9]/g, '');
            el.value = el.value.toUpper()
        })
    }
})

Vue.directive('checkMinMaxValue', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress', function(e) {
            const regex = /[0-9,]/g;
            if (!regex.test(e.key)) {
                e.preventDefault();
            }
        })

        el.addEventListener('keyup', () => {
            if (parseFloat(el.value) > parseFloat(el.max)) {
                el.value = parseFloat(el.max)
            }
            if (parseFloat(el.value) < parseFloat(el.min)) {
                el.value = parseFloat(el.min)
            }
        })
    },
    update: function(el) {
        if (parseFloat(el.value) > parseInt(el.max)) {
            el.value = el.max
        }
        if (parseFloat(el.value) < parseFloat(el.min)) {
            el.value = 1
        }
    }
})

Vue.directive('only-number-and-enter', {
    bind: function(el, binding,vnode) {
        if (binding.value == true) {
            el.addEventListener('keypress', function(e) {
                const regex = /[0-9,]/g;
                if (!regex.test(e.key) && e.key!='Enter') {
                    e.preventDefault();
                }
            })
            el.addEventListener('paste', function(e) {
                el.value = el.value.replace(/[^(\r\n|\n|\r|\d)]/g, "");
                el.dispatchEvent(new Event('input'));
            })

        }
    },
    update: function(el,binding) {
        if (binding.value == true) {
            el.value = el.value.replace(/[^(\r\n|\n|\r|\d)]/g, "");
            el.dispatchEvent(new Event('input'));
        }
    }
})


function replaceCommatoPoint(el){
     el.value = el.value.replace(',','.')
}

function conditionFunc(e) {
    if(this.value.length == 4) e.preventDefault();
    const regex = /[0-9,.]/g;
    if (!regex.test(e.key)) {
        e.preventDefault();
    }
}

function maxAndMin(){
    let el = this
    el.addEventListener('keyup', () => {
        if (parseFloat(el.value) > parseFloat(el.max)) {
            el.value = parseFloat(el.max)
        }
        if (parseFloat(el.value) < parseFloat(el.min)) {
            el.value = parseFloat(el.min)
        }
    })
    replaceCommatoPoint(this)
}


Vue.directive('decimal-numbers', {
    bind(el, binding, vnode) {
        el.addEventListener('keypress',conditionFunc )
        el.addEventListener('keyup',maxAndMin)
    },
    unbind(el, binding, vnode) {
        el.removeEventListener('keypress',conditionFunc )
        el.removeEventListener('keyup',maxAndMin)
    },

})

