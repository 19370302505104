const routes = [
    {
        path: "/unidentified/payments",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission: 'unidentifiedpayment_index'
        }
    }
];

export default routes;
