const module = {
    namespaced: true,
    state: () => ({
        studentNationalityCode: "",
    }),
    getters: {
        getStudentNationalityCode(state) {
            return state.studentNationalityCode;
        },
    },
    mutations: {
        setStudentNationalityCode(state, value) {
            state.studentNationalityCode = value;
        },
    },
    actions: {},
};

export default module;
