const routes = [
    {
        path: "/vinov/instructions",
        component: () => import("../page/Index"),
        meta: {
            isAuthenticated: true,
            permission:"vinovinstruction_index"
        }
    }
];

export default routes;
