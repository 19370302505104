import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unihall/quotas', config);
}

const get = async (id,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/unihall/quotas/'+id, config);
}

const update = async (id,data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/unihall/quotas/'+id, data);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/unihall/quotas/'+id);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/unihall/quotas', data);
}
const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/unihall/excel-export', config);
}


export default {
    getAll,
    get,
    store,
    update,
    del,
    exportExcel
};
