const routes = [
    {
        path: "/online/registration/login",
        component: () => import("../pages/Login"),
    },
    {
        path: "/online/registration",
        component: () => import("../pages/Index"),
        meta: {
            isOnlineRegistration: true
        }
    },
    {
        path: "/online/registration/success",
        component: () => import("../pages/Success"),
    }
];

export default routes;
