const routes = [
    {
        path: "/payment/packages",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            //permission: "period_index"
        }
    },
    {
        path: "/payment/packages/:id",
        component: () => import("../pages/Products.vue"),
        meta: {
            isAuthenticated: true,
            //permission: "period_index"
        }
    }
];

export default routes;
