const routes = [
    {
        path: "/program/basic-fields",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "programbasicfield_index"
        }
    }
]

export default routes;
