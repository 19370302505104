
const routes = [
    {
        path:'/prevent/course/registrations/payment',
        component:()=>import('../pages/Payment.vue'),
        meta:{
            isAuthenticated:true
        }
    }
]

export default  routes
