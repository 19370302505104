const routes = [
    {
        path: "/receipts",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission: 'receipt_index'
        }
    },
    {
        path: "/my-receipts",
        component: () => import("../pages/Student.vue"),
        meta: {
            isAuthenticated: true ,
            permission: 'receipt_student'
        }
    }
];

export default routes;
