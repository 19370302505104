const routes = [
    {
        path: "/course/transfers",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "coursetransfer_index",
        }
    },
    {
        path: "/course/transfers/evaluation/:id",
        component: () => import("../pages/Evaluation"),
        meta: {
            isAuthenticated: true,
            permission: "coursetransfer_show"
        }
    },

    {
        path: "/course/transfers/form",
        component: () => import("../pages/CourseTransfersForm"),
        meta: {
            isAuthenticated: true,
            isStudent: true
        }
    },
];

export default routes;
