const routes = [
    {
        path: "/staff",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "staff_index"
        }
    },
    {
        path: "/staff/extra-payment",
        component: () => import("../pages/ExtraPayment.vue"),
        meta: {
            isAuthenticated: true,
            permission: "staff_extrapayment"
        }
    }
];

export default routes;
