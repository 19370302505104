<template>
    <div id="print-content">
        <slot name="header" />
        <SidebarMenu />
        <div class="main-content">
            <slot name="header-mobile"></slot>
            <slot></slot>
            <template v-if="!onLine">
                check internet
            </template>
        </div>
        <div class="d-flex flex-column vh-100" v-if="hasFooter">
            <Footer />
        </div>
        <DeleteModal />
        <ConfirmModal />
        <div id="pdfViewer">
            <b-modal id="pdfViewerModal" hide-footer size="xl" :onHideOnlyX="true"
                @bHideModalHeaderClose="pdfViewerModalClose()">
                <template #modal-title>
                </template>
                <div class="d-flex justify-content-end mb-2">
                    <b-button @click="pdfPrint">{{ $t('print') }}</b-button>
                </div>
                <vue-pdf-app :pdf="pdfFileData" :fileName="pdfFileName" :config="pdfFileConfig"
                    :page-scale="'page-actual'" v-if="pdfFileShow" />
                <div class="row" v-if="actionButton">
                    <div class="col-12 d-flex mt-4">
                        <b-button @click="actionFunc(note)" variant="primary" class="mr-2">{{ actionButtonLabel
                            }}</b-button>
                        <b-button @click="pdfViewerModalClose">{{ $t('cancel') }}</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        <div id="htmlViewer">
            <b-modal id="htmlViewerModal" hide-footer size="xl" :onHideOnlyX="true"
                @bHideModalHeaderClose="htmlViewerModalClose()">
                <template #modal-title>
                </template>
                <div v-if="printValue">
                    <b-button @click="download" variant="primary" class="mr-2">{{ this.$t('download') }}</b-button>
                </div>
                <div v-html="dataHtml" />
            </b-modal>
        </div>
        <div id="detailErrors">
            <b-modal id="detailErrorsModal" hide-footer centered size="lg" :onHideOnlyX="true"
                @bHideModalHeaderClose="detailErrorsModalClose()">
                <template #modal-title>
                    {{ $t('error') }}
                </template>
                <div>
                    <h5 v-if="detailErrors && detailErrors.title">
                        {{ detailErrors.title }}
                    </h5>
                    <div v-if="detailErrors && detailErrors.errors" style="max-height: 400px; overflow-y: auto">
                        <ol>
                            <li v-for="(error, i) in detailErrors.errors" :key="i">
                                <div v-for="(key, index) in getKeys(error.data)" :key="index">
                                    <span v-html="$t(key, { 'interpolation': { 'escapeValue': false } })"></span>
                                    :{{ error.data ? error.data[key] : '' }}
                                </div>
                                {{ error.data ? error.data : '' }} - {{ error.message }}
                            </li>
                        </ol>
                    </div>
                </div>
            </b-modal>
        </div>
        <div id="captchaDiv">
            <b-modal id="captchaModal" centered hide-footer size="md" :onHideOnlyX="true"
                @bHideModalHeaderClose="pdfViewerModalClose()">
                <template #modal-title>
                </template>

                <div class="col-12 d-flex justify-content-center">
                    <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="compact"
                        sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
                </div>

            </b-modal>
        </div>
        <CommonModal ref="notificationModal" size="lg" :onHideOnlyX="true"
            @bHideModalHeaderClose="$store.state.user.notificationModalValue = false">
            <template v-slot:CommonModalTitle>
                <template v-if="$store.state.user.notification">
                    {{ $store.state.user.notification.title.toUpper() }}
                </template>
            </template>
            <template v-slot:CommonModalContent>
                <p>
                    {{ $t('date') }}: {{
                $moment($store.state.user.notification?.created_at).format("DD.MM.YYYY HH:mm") }}
                </p>
                <p v-if="$store.state.user.notification">
                    {{ $store.state.user.notification.message }}
                </p>
                <div class="mt-3" v-if="$store.state.user.notification?.link">
                    <router-link :to="`${$store.state.user.notification.link}`" target="_blank">
                        <b-button variant="primary">
                            {{ $t('show_application') }}
                        </b-button>
                    </router-link>
                </div>
            </template>
        </CommonModal>
        <iframe id="print-frame"></iframe>
    </div>
</template>

<script>
import SidebarMenu from '@/layouts/AppLayout/SidebarMenu'
import Footer from '@/layouts/AppLayout/Footer.vue'

// Pdf
import VuePdfApp from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';

// Modal
import DeleteModal from '@/components/elements/DeleteModal';
import ConfirmModal from '@/components/elements/ConfirmModal';

import NotificationsService from "@/services/NotificationsService";

// Event Bus
import { EventBus } from '@/main'
import { VueRecaptcha } from 'vue-recaptcha'

// Services
import AuthService from "@/services/AuthService"
import Base from "@/plugins/Base"

export default {
    name: 'AppLayout',
    components: {
        SidebarMenu,
        Footer,
        VuePdfApp,
        DeleteModal,
        ConfirmModal,
        VueRecaptcha
    },
    data() {
        return {
            note: null,
            onLine: navigator.onLine,
            showBackOnline: false,
            pdfFileShow: false,
            pdfFileData: null,
            pdfFileName: null,
            pdfFileConfig: {
                sidebar: {
                    viewThumbnail: true,
                    viewOutline: true,
                    viewAttachments: true
                },
                secondaryToolbar: {
                    secondaryPresentationMode: false,
                    secondaryOpenFile: false,
                    secondaryPrint: false,
                    secondaryDownload: true,
                    secondaryViewBookmark: true,
                    firstPage: true,
                    lastPage: true,
                    pageRotateCw: true,
                    pageRotateCcw: true,
                    cursorSelectTool: false,
                    cursorHandTool: false,
                    scrollVertical: false,
                    scrollHorizontal: false,
                    scrollWrapped: false,
                    spreadNone: false,
                    spreadOdd: false,
                    spreadEven: false,
                    documentProperties: false
                },
                toolbar: {
                    toolbarViewerLeft: {
                        findbar: true,
                        previous: true,
                        next: true,
                        pageNumber: true
                    },
                    toolbarViewerRight: {
                        presentationMode: false,
                        openFile: false,
                        print: false,
                        download: true,
                        viewBookmark: false
                    },
                    toolbarViewerMiddle: {
                        zoomOut: true,
                        zoomIn: true,
                        scaleSelectContainer: true
                    }
                },
                errorWrapper: true

            },
            dataHtml: '',
            actionButton: false,
            actionButtonLabel: null,
            actionFunc: function () {
                return false;
            },
            detailErrors: {},
            printValue: true
        }
    },
    computed: {
        hasFooter() {
            return this.$route.meta.hasFooter;
        }
    },
    watch: {
        onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        },
        '$store.state.user.notificationModalValue': {
            async handler(val) {
                if (val) {
                    this.$refs.notificationModal.$refs.commonModal.show()
                    const notificationItem = this.$store.state.user.notification

                    await NotificationsService.read(notificationItem._id)
                    this.$store.dispatch('user/getNotifications', { page: 1, read: 1 });
                    this.$store.dispatch('user/getNotifications', { page: 1, read: 0 });
                }
            },
            immediate: true
        }
    },
    methods: {
        async pdfPrint() {
            await this._pdfPrint(this.pdfFileData, "application/pdf")
        },
        download() {
            this.printValue = false
            setTimeout(() => {
                window.print()
                this.printValue = true
            }, 100);
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.onLine = type === 'online';
        },

        // Pdf Viewer
        pdfViewerModalShow(event) {
            if (event.hasOwnProperty('config')) {
                this.actionButton = event.config.actionButton
                this.actionButtonLabel = event.config.actionButtonLabel
                this.actionFunc = event.config.actionFunc
            } else {
                this.actionButton = false
                this.actionButtonLabel = ''
                this.actionFunc = () => {
                    return false;
                }
            }
            this.pdfFileData = event.pdfFileData
            this.pdfFileName = event.pdfFileName
            this.note = event.note
            this.pdfFileShow = true;
            this.$bvModal.show('pdfViewerModal')
        },
        pdfViewerModalClose() {
            this.$bvModal.hide('pdfViewerModal')
            this.pdfFileShow = false;
            this.pdfFileData = null;
            this.pdfFileName = null;
        },

        // Html Viewer
        htmlViewerModalShow(event) {
            this.$bvModal.show('htmlViewerModal')
            this.dataHtml = event;
            this.printValue = true
        },
        htmlViewerModalClose() {
            this.dataHtml = '';
            this.$bvModal.hide('htmlViewerModal')
        },

        // Detail Errors
        detailErrorsModalShow(event) {
            this.detailErrors = event;
            this.$bvModal.show('detailErrorsModal')
        },
        detailErrorsModalClose() {
            this.detailErrors = {}
            this.$bvModal.hide('detailErrorsModal')
        },
        getKeys(data = {}) {
            return Object.keys(data)
        },
        captchaModalShow(event) {
            this.$bvModal.show('captchaModal')
        },
        onCaptcha(child) {
            this.$refs.recaptcha.execute();
            this.child = child;
        },
        onCaptchaVerified: function (recaptchaToken) {
            let data = {
                g_recaptcha_token: recaptchaToken
            }
            AuthService.refreshToken(data).then(response => {
                let data = response.data.data
                //Set token
                data.access_token = data.access_token.replace(/^"+/, '').replace(/"+$/, '')
                Base.LocalStorage.set('access_token', 'Bearer ' + data.access_token)
                //Set expire in
                let expireIn = parseInt(data.expires_in) * 60000;
                Base.LocalStorage.set('access_token_expires_in', new Date().getTime() + expireIn)
                let expireInIn = Math.floor(+ Date.now() / 1000) + (parseInt(data.expires_in) * 60)
                Base.LocalStorage.set('expire_in', expireInIn)
                this.$router.go()
            }).catch(e => {
                this.showErrors(e)
            })
        },

    },
    beforeCreate() {
        this.$store.dispatch('auth/initAppPage');
    },
    mounted() {
        EventBus.$on('pdfViewerModalShow', (event) => {
            this.pdfViewerModalShow(event)
        });
        EventBus.$on('htmlViewerModalShow', (event) => {
            this.htmlViewerModalShow(event)
        })
        EventBus.$on('detailErrorsModalShow', (event) => {
            this.detailErrorsModalShow(event)
        })
        EventBus.$on('captchaModalShow', (event) => {
            this.captchaModalShow(event)
        })

        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.$store.dispatch('user/getTwoFactorStatus');
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
}
</script>


<style scoped>
#print-frame {
    height: 0px;
    width: 0px;
    position: absolute;
    display: none;
}

#captchaModal___BV_modal_outer_ {
    z-index: 3000 !important;
}
</style>
