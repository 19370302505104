const routes = [
    {
        path: "/send/sms",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "semester_index"
        }
    },
    {
        path: "/send/sms/search/student",
        component: () => import("../pages/SendingSmsStudentSearch.vue"),
        meta: {
            isAuthenticated: true,
            permission: "semester_index"
        }
    },
    {
        path: "/send/sms/to-staff",
        component: () => import("../pages/SendingSmsStaff.vue"),
        meta: {
            isAuthenticated: true,
            permission: "semester_index"
        }
    }
];

export default routes;
