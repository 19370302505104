
const routes = [
    
    {
        path: "/payzee/complete-purchase",
        component: () => import("../pages/completePurchase"),
    },
    // {
    //     path: "/transfer/register",
    //     component: () => import("../pages/transfer/Register"),
    // },
];

export default routes;
