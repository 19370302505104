import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff', config);
}

const search = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/search', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/' + id);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/staff', formData);
}

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/staff/' + id, formData);
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/staff/' + id);
}

// ROLE
const byRole = async (role) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/by/role/' + role);
}

const staffByRole = async (role) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/by/role/' + role);
}

const downloadAsEXcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/excel-export',{...config,responseType:'arraybuffer'});
}

const getNewPassword = (userId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/password-reminder/'+ userId);
}

const importExtraPayments = (formData)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/staff/import-extra-payments', formData);
}

const getExtraPayment = ()=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/staff/extra-payment');
}

const personUploadSignature = (formData,userId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/persons/upload-signature/'+userId, formData);
}

const personGetSignature = (userId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/persons/signature/'+userId, {responseType: 'arraybuffer'});
}

export default {
    getAll,
    search,
    get,
    store,
    update,
    del,
    byRole,
    staffByRole,
    downloadAsEXcel,
    getNewPassword,
    importExtraPayments,
    getExtraPayment,
    personUploadSignature,
    personGetSignature
}
