import Vue from 'vue'
import moment from 'moment'
import i18n from "@/plugins/i18n"

// Time Ago
Vue.filter('timeAgo', (value, locale = 'tr') => {
    const lang = {
        tr: {
            minute: 'Dakika',
            hour: 'Saat',
            day: 'Gün',
        },
        en: {
            minute: 'Minute',
            hour: 'Hour',
            day: 'Day',
        },
    };

    let now = Math.round((Date.now() / 1000));
    let difference = now - value;

    if (difference < 3600) {
        let minute = Math.round(difference / 60);
        if (minute == 0) minute = 1;
        return minute + ' ' + lang[locale].minute;
    }
    else if (difference < 86400) {
        let hour = Math.round(difference / 3600);
        return hour + ' ' + lang[locale].hour;
    }
    else {
        let day = Math.round(difference / 86400);
        return day + ' ' + lang[locale].day;
    }
});

// Time Format
Vue.filter('timeFormat', (value, format = 'HH:mm') => {
    if (!value) return ''
    else return moment(value * 1000).format(format);
});

// Date Format
Vue.filter('dateTimeFormat', (value, locale = 'tr') => {
    if (!value) return ''
    else {
        if (moment(value * 1000).format('DD/MM/YY') == moment().format('DD/MM/YY')) {
            return moment(value * 1000).format('HH:mm');
        }
        else {
            if (locale == 'tr') return moment(value * 1000).format('DD MM YYYY HH:mm');
                           else return moment(value * 1000).format('YY MM DD HH:mm');
        }
    }
});

Vue.filter('dateFormat', (value, locale = 'tr') => {
    if (!value) return ''
    else {
        return moment(value).format('DD.MM.YYYY');

    }
});


// Uppercase
Vue.filter('toUpperCase', (value) => {
    return value.toLocaleUpperCase(i18n.locale);
});

Vue.filter('formatToCurrency',(number, currency)=>{
    return new Intl.NumberFormat('tr-TR',  {style:'currency', currency: currency,  minimumFractionDigits: 2}).format(number);
});

Vue.filter('formatNumber',(number)=>{
    return new Intl.NumberFormat('tr-TR',  {minimumFractionDigits: 2}).format(number);
});
