<template>
    <div class="mt-3">
        <b-pagination v-model="innerValue.currentPage" pills :total-rows="innerValue.total" :per-page="innerValue.perPage"
            first-number last-number></b-pagination>
    </div>
</template>

<script>
export default {
    name: "PagePagination",
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        'innerValue.currentPage': {
            handler(newVal, oldVal) {
                if (newVal > 0 && newVal !== oldVal) {
                    this.$emit("getPage", newVal)
                }
            }
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("input", val)
            }
        }
    }
}
</script>
