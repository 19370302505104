const translations = {

    document_request_info_text: "Öğrencilerimizin talep ettikleri (öğrenci belgesi, transkript, disiplin sicili, dil yeterlik belgesi vb.) belgeleri kolay temin etmelerini sağlamak amacıyla 27.07.2022 tarihinden itibaren OIS üzerinden gerçekleştirilecek talepler nitelikli elektronik imzalı olarak alınabilecektir. Ancak ıslak imzalı belge talep eden öğrencilerimiz, OIS üzerinden başvurularını gerçekleştirdikten sonra bu belgelerini Beşiktaş Kampüsü Öğrenci İşleri Daire Başkanlığı’ndan almaları gerekmektedir.Gerek ıslak imza gerekse de nitelikli elektronik imzalı olarak aldığınız belgelerin “resmî belge” niteliğini haiz olması nedeniyle; yürürlükteki yasa ve yönetmeliklere aykırı kullanılması, değiştirilmesi, veriliş amacına aykırı olarak kullanılması ve başkasına kullandırılmasının Yükseköğretim Kurumları Öğrenci Disiplin Yönetmeliği kapsamında disiplin cezasını gerektiren eylemlerden olduğu gibi Türk Ceza Kanunu kapsamında da suç teşkil ettiğini hatırlatmak isteriz. Öğrenci belgenizi E-Devlet şifresi ile E-Devlet üzerinden alabilirsiniz. E-Devlet üzerinden alınan öğrenci belgeleri tüm kurum ve kuruluşlarda geçerlidir.",
    kvkk_content_pilotaj: "Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium",
    info_text_content: "Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium ",
    kvkk_content: 
    "<h4>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Lisansüstü Öğrenciler</h4>"+
    "Bu metin, Bahçeşehir Üniversitesi’nin kişisel verilerinizi nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin işlenmesine ilişkin konular, aşağıda başlıklar halinde sıralanmıştır."+
    "<div class='mb-2'><b>	Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
    "Bahçeşehir Üniversitesi (“BAU”) olarak, lisansüstü programlarda öğrenim gören öğrencilerimizin kişisel verilerinin nasıl "+
    " işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Bir diğer ifadeyle, kişisel verilerinizi işlerken "+
    "veri sorumlusu olarak hareket ediyoruz.  <br/><br/>"  +
    "<div class='mb-2'><b> Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor?</b></div>" +
    "Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz: <br/><br/>" +
    "− <b>	Kişisel verilerinizi toplama kanalları </b>  ➢  Fiziki ortamlarda form doldurmanız veya bilgilerinizi bizimle paylaşmanız;"+
    "öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital ortamlarda yer alan iletişim formlarını doldurmanız;"+
    "telefon, e-posta, sosyal medya hesapları veya BAU whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar"+
    "aracılığıyla kişisel verileriniz toplanmaktadır.   <br/><br/>" +
    "− <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢  Kişisel verileriniz, kısmen otomatik  veya veri kayıt" +
    "sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.<br/><br/>" +
    "− <b>Kişisel  verilerinizin  toplanmasının  hukuki  sebepleri</b>  ➢ Kişisel  verileriniz,  Kanun’un  5." +
    "maddesinde belirtilen hukuki sebeplerden hukuki yükümlülüklerimizin yerine getirilmesi," +
    "sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin" +
    "zorunlu olması, ilgili  kişinin  temel  hak  ve  özgürlüklerine  zarar  vermemek  kaydıyla  veri" +
    "işlenmesinin meşru menfaatlerimiz için zorunlu olması ile ilgili kişinin açık rızasının buluması" +
    "hukuki sebeplerine dayalı olarak işlenmektedir. <br/>" +
    " <br/>" +
    "<div class='mb-2'><b> Hangi kişisel verilerim işleniyor?</b></div>" +
    "BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br/>" +
    "<div class='mt-2 mb-4 table-dropdown no-scrollbar border rounded  table-responsive'>"+
    "<table class='table b-table table-striped table-bordered'>"+
    "<thead>"+
    "<tr>"+
    "<th>Kimlik</th>"+
    "<th>İletişim</th>"+
    "<th>Eğitim</th>"+
    "<th>Diğer Bilgileriniz</th>"+
    "</tr>"+
    "</thead>"+
    "<tbody>"+
    "<tr>"+
    "<td>Ad-soyad, kimlik numarası, cinsiyet, uyruk, doğum yeri ve tarihi, kimlik fotokopisi, öğrenci numarası, uluslararası öğrencilerimizin pasaport bilgileri</td>"+
    "<td>Telefon numarası, e-posta adresi, iş ve ev adresi.</td>"+
    "<td>Lisansüstü başvuru bilgileri, okul/fakülte/bölüm bilgileri, mezuniyet ortalaması, mezuniyet yılı ve derecesi, ingilizce yeterlilik bilgileri, diploma ve transkript bilgileri, alınan dersler, proje, ödev, sunum, tez vb. eğitim materyalleri, danışman bilgisi, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, uluslararası öğrencilerimizin kabul ve denklik bilgileri</td>"+
    "<td>Askerlik durumu, çalıştığınız sektör, çalıştığınız kurum, mesleğiniz, çalıştığınız birim, unvanınız, iş deneyiminiz, fotoğrafınız, özgeçmiş ve referans bilgileri, işlem güvenliği bilgileri, öğrenci işlem kayıtları, imza, banka ve IBAN bilgileri.</td>"+
    "</tr>"+
    "</tbody>"+
    "</table>"+
    "</div>"+
    "<del>Mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ayrıca sağlık verilerini de işliyoruz.</del>"+
    "<div class='mb-2'><b>  Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
    "Kişisel Verilerin Korunması Kanunu’nun (\"Kanun”) 5. maddesi, kişisel verilerin işlenmesine ilişkin hukuki" +
    "sebepleri düzenlemektedir. Kişisel verilerinizi bu madde kapsamında, aşağıda belirtilen amaçlar ve hukuki sebeplere uygun olarak la yurtiçinde veya yurtdışında işliyoruz:</br><br/>" +
    "<div class='row'>"+
    "<div class='col-4'>Sözleşmenin kurulması veya ifası</div>"+
    "<div class='col-8'>"+
    "Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, <hr>"+ 
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</div>"+
    "<div class='col-8'>"+
    "Öğrenci kayıt sözleşmesinin imzalanması; lisansüstü programları kayıt, nakil ve kayıt dondurma/iptal "+
    "süreçlerinin yürütülmesi. Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması,"+
    " Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi,"+
    " BAU öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi,"+
    " Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
    " Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması,"+
    " Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması,"+
    " Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması,"+
    " Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi,"+
    "<hr>"+
    "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de"+
    " dahil olmak üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi,"+
    " Öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin"+
    " alınması ve sonuçlandırılması, Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi,"+
    " Faaliyetlerimizin iyileştirilmesine ilişkin çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması,"+
    " Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına ilişkin süreçlerin yürütülmesi; bu kapsamda,"+
    " öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe süreçlerinin yürütülmesi."+
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</div>"+
    "<div class='col-8'>"+
    "Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, "+
    "yüksek lisans, burs ve benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda"+
    " haklarımızın korunabilmesi, kullanılabilmesi ve yasal süreçlerin yürütülmesi.<hr>"+ 
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Veri işlemenin Kanunlarda açıkça öngörülmesi ve"+
    " hukuki yükümlülüklerimizin yerine getirilmesi</div>"+
    "<div class='col-8'>"+
    "Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili" +
    "kurum veya kuruluşların Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü "+
    "durumlarda, yasal yükümlülüklerimizin yerine getirilmesi"+
    "</div>"+
    "<div class='col-4'>Kayıt İşlemleri</div>"+
    "<div class='col-8'>"+
    "Lisansüstü programları kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi<hr>"+ 
    "</div>"+
    "<div class='col-4'>Eğitim ve Öğretim Faaliyetleri</div>"+
    "<div class='col-8'>"+
    "Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
    " öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve"+
    " sosyal gelişimlerinin takibi, ölçme ve değerlendirme süreçlerinin yürütülmesi<hr>"+ 
    "</div>"+
    "<div class='col-4'>Organizasyon ve Etkinlikler</div>"+
    "<div class='col-8'>"+
    "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi.<hr>"+ 
    "</div>"+
    "<div class='col-4'>Diğer Faaliyetlerimizdeki Amaçlar</div>"+
    "<div class='col-8'>"+
    "İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama"+
    " kullanıcı hesaplarının oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması,"+
    " faaliyetlerimizin denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi,"+
    " faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe"+
    " süreçlerinin yürütülmesi. <hr>"+ 
    "</div>"+
    "</div>"+
    "<div class='mb-2'><b> Kişisel verilerimin aktarıldığı taraflar ve aktarılma amaçları nelerdir  başkalarına aktarılıyor mu? </b></div>" +
    "Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir: <br/>" +
    "<ul>"+
    "<li>	Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki"+
    " yükümlülüklerimizin yerine getirilmesi  → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
    "<li>	Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın " +
    " devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.</li>" +
    "<li>	Denetim ve raporlamaların yapılabilmesi, organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, iş ortaklarımız ile olan ilişkilerin yönetimi,"+
    " Bahçeşehir Grup Şirketleri politika ve kurallarına uyumluluğun sağlanması → İş Ortakları.  </li>" +
    "<li>	Kişisel verileriniz üçüncü taraflarla paylaşılırken Kanun’un 8. ve 9. maddelerinde belirtilen kurallara uygunluk sağlıyoruz.</li>" +
    "</ul>"+
    "<div class='mb-2'><b> Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örneklerileri nelerdir?</b></div>" +
    "Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
    "açıklanmıştır: verilerinizi işlerken Kişisel Verilerin Korunması Kanunu’nun 5. maddesinde belirtilen “sözleşmenin kurulması "+ 
    " veya ifası”, “bir hakkın tesisi, kullanılması veya korunması”, “kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması” "+
    "ile “hukuki yükümlülüklerimizin yerine getirilmesi” hukuki sebeplerine dayanmaktayız. Bu hukuki sebepler, aşağıda örnek veri işleme "+
    "faaliyetleri ile açıklanmıştır: <br/>" +
    " <br/>" +
    " <br/>" +
    "<b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br/>" +
    "Sözleşmenin kurulması veya ifası" +
    " <br/><br/>" +
    "<b>- Örneğin, ders seçme/dersten çekilme işlemlerinin yerine getirilmesi.</b>  <br/>" +
    "Bir hakkın tesisi,kullanılması veya korunması" +
    " <br/><br/>" +
    "<b>- Örneğin, üniversite, fakülte ya da öğrenci etkinlikleri hakkında duyuruların yapılması.</b>  <br/>" +
    "Meşru menfaatlerimiz için zorunlu olması" +
    " <br/><br/>" +
    "<b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br/>" +
    "Hukuki yükümlülüklerimiz"+
    "<br/><br/>" +
    "<div class='mb-2'><b> Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
    "Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
    "haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
    "Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
    "aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
    "<ul>" +
    "<li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
    "<li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
    "<li>E-posta yoluyla (1) → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
    "</ul>"+
    "Sistemlerimizde daha önce kayıtlı bir e-posta adresinizin olması ve bu adresi kullanmanız suretiyle.<br><br>"+
    " <br/>" +
    "<div class='mb-2'><b> Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
    "BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br/>" +
    "<ul>"+
    "<li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
    "<li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>"+
    "</ul>",
        kvkk_lisans_content: 
        "<h4>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Lisans Öğrencileri</h4>"+
        "Bu metin, Bahçeşehir Üniversitesi’nin (“BAU”) öğrenci kişisel verilerini nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin   işlenmesine   ilişkin   konular,   aşağıda   başlıklar   halinde sıralanmıştır."+
        "<div class='mb-2'><b> Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
        "Bahçeşehir Üniversitesi olarak, öğrencilerimizin kişisel verilerinin nasıl"+
        " işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Bir diğer ifadeyle,"+
        " kişisel verilerinizi işlerken veri sorumlusu olarak hareket ediyoruz. <br/><br/>"  +
        "<div class='mb-2'><b>  Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor? </b></div>" +
        "Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:  <br/><br/>" +
        "− <b>Kişisel verilerinizi toplama kanalları</b>  ➢  Fiziki ortamlarda form doldurmanız veya bilgilerinizi" +
        "bizimle paylaşmanız; öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital" +
        "ortamlarda yer alan iletişim formlarını doldurmanız; telefon, e-posta, sosyal medya hesapları" +
        "veya BAU whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar" +
        "aracılığıyla kişisel verileriniz toplanmaktadır.  <br/><br/>" +
        "− <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢  Kişisel verileriniz, kısmen otomatik  veya veri kayıt" +
        "sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.  <br/><br/>" +
        "− <b>Kişisel  verilerinizin  toplanmasının  hukuki  sebepleri</b>  ➢ Kişisel  verileriniz,  Kanun’un  5." +
        "maddesinde belirtilen hukuki sebeplerden hukuki  yükümlülüklerimizin  yerine  getirilmesi," +
        "sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin" +
        "zorunlu  olması,  ilgili  kişinin  temel  hak  ve  özgürlüklerine  zarar  vermemek  kaydıyla  veri" +
        "işlenmesinin meşru menfaatlerimiz için zorunlu olması" +
        "hukuki sebeplerine dayalı olarak işlenmektedir. <br/>" +
        " <br/>" +
        "<div class='mb-2'><b> Hangi kişisel verilerim işleniyor?</b></div>" +
        "BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br/>" +
        "<div class='mt-2 mb-4 table-dropdown no-scrollbar border rounded  table-responsive'>"+
        "<table class='table b-table table-striped table-bordered'>"+
        "<thead>"+
        "<tr>"+
        "<th>Kimlik</th>"+
        "<th>İletişim</th>"+
        "<th>Eğitim</th>"+
        "<th>Diğer Bilgileriniz</th>"+
        "</tr>"+
        "</thead>"+
        "<tbody>"+
        "<tr>"+
        "<td>Ad-soyad, anne-baba adı, kimlik numarası, uyruk, doğum yeri ve tarihi, cinsiyet, öğrenci numarası</td>"+
        "<td>Telefon numarası, adres, eposta adresi</td>"+
        "<td>Fakülte, bölüm, sınıf, staj bilgileri, danışman bilgisi, proje, ödev, sunum, tez vb. eğitim materyalleri, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, transkript, alınan dersler, hazırlık okuma bilgisi, ÖSYM yerleşme ve puan bilgileri, yatay geçiş bilgisi, yan dal/çift ana dal bilgisi, kredi bilgileri, mezun olunan okul bilgileri.</td>"+
        "<td>Fotoğraf, meslek bilgisi, banka bilgileri, öğrenci işlem kayıtları, IBAN, gelir bilgisi, disiplin işlemleri bilgileri, işlem güvenliği bilgileri, imza, mazeret nedeni, ayrılma/kayıt dondurma nedeni, kayıt, mezuniyet ve ayrılma tarihleri, yakınlarınızın kimlik ve iletişim bilgileri, anket çalışmaları neticesinde elde edilen bilgiler.</td>"+
        "</tr>"+
        "</tbody>"+
        "</table>"+
        "</div>"+
        "Pilotaj bölümüne kayıt yatptırmak isteyen öğrencilerimizin,"+
        "<del>mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ve engelli öğrencimizin öğrencilerimizin</del>"+
        " ayrıca sağlık verilerini de işliyoruz. Plotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin adli sicil kaydına ilişkin bilgilerini de işliyoruz.<br/><br/>" +
        "<div class='mb-2'><b> Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
        "Kişisel Verilerin Korunması Kanunu’nun (\"Kanun”) 5. maddesi, kişisel verilerin işlenmesine ilişkin hukuki" +
        "sebepleri düzenlemektedir.  Kişisel verilerinizi  bu madde kapsamında, aşağıda belirtilen amaçlar  ve" +
        "hukuki sebeplere uygun olarak "+
        "<del>la yurtiçinde veya yurtdışında</del> işliyoruz:</br><br/>" +
        "<div class='row'>"+
        "<div class='col-4'>Sözleşmenin kurulması veya ifası</div>"+
        "<div class='col-8'>"+
        "Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, <hr>"+ 
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</div>"+
        "<div class='col-8'>"+
        "Öğrenci kayıt sözleşmesinin imzalanması; lisans programları kayıt, nakil ve kayıt dondurma/iptal "+
        "süreçlerinin yürütülmesi. Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması,"+
        " Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi,"+
        " BAU öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi,"+
        " Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
        " Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması,"+
        " Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması,"+
        "Öğrenci zorunlu-gönüllü staj işlemlerinin yürütülmesi"+
        " Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması,"+
        " Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi,"+
        "<hr>"+
        "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de"+
        " dahil olmak üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi,"+
        " Öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin"+
        " alınması ve sonuçlandırılması, Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi,"+
        " Faaliyetlerimizin iyileştirilmesine ilişkin çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması,"+
        " Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına ilişkin süreçlerin yürütülmesi; bu kapsamda,"+
        " öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe süreçlerinin yürütülmesi."+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</div>"+
        "<div class='col-8'>"+
        "Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, "+
        "yüksek lisans, burs ve benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda"+
        " haklarımızın korunabilmesi, kullanılabilmesi ve yasal süreçlerin yürütülmesi.<hr>"+ 
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Veri işlemenin Kanunlarda açıkça öngörülmesi ve"+
        " hukuki yükümlülüklerimizin yerine getirilmesi</div>"+
        "<div class='col-8'>"+
        "Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili" +
        "kurum veya kuruluşların Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü "+
        "durumlarda, yasal yükümlülüklerimizin yerine getirilmesi"+
        "Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin ceza mahkumiyeti verileri, mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi, öğrencinin pilotaj bölümüne kayıt için uygunluğunun tespit edilmesi ve yetkili kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir."+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>İlgili kişinin açık rızasının bulunması</div>"+
        "<div class='col-8'>"+
        "Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin sağlık verileri, sağlık durumunun kontrolü, sağlık durumunun bölüm yeterliliğini karşılayıp karşılamadığının tespiti, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir."+
        "</div>"+
        "<div class='col-4'>Üniversite kayıt İşlemleri</div>"+
        "<div class='col-8'>"+
        "Üniversite kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, oryantasyon aktivitelerinin gerçekleştirilmesi<hr>"+ 
        "</div>"+
        "<div class='col-4'>Eğitim ve Öğretim Faaliyetleri</div>"+
        "<div class='col-8'>"+
        "Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
        " öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve"+
        " sosyal gelişimlerinin takibi, ölçme ve değerlendirme süreçlerinin yürütülmesi, zorunlu-gönüllü staj işlemlerinin yürütülmesi <hr>"+ 
        "</div>"+
        "<div class='col-4'>Organizasyon ve Etkinlikler</div>"+
        "<div class='col-8'>"+
        "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi.<hr>"+ 
        "</div>"+
        "<div class='col-4'>Diğer Faaliyetlerimizdeki Amaçlar</div>"+
        "<div class='col-8'>"+
        "İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama"+
        " kullanıcı hesaplarının oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması, anket çalışmalarının"+
        " yürütülmesi, faaliyetlerimizin denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi,"+
        " faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe"+
        " süreçlerinin yürütülmesi. <hr>"+ 
        "</div>"+
        "</div>"+
        "<div class='mb-2'><b> Kişisel verilerimin aktarıldığı taraflar ve aktarılma amaçları nelerdir  başkalarına aktarılıyor mu? </b></div>" +
        "Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir:  <br/>" +
        "<ul>"+
        "<li>Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi," +
        " hukuki yükümlülüklerimizin yerine getirilmesi → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
        "<li>Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın" +
        " devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.  </li>" +
        "<li>	Denetim ve raporlamaların yapılabilmesi, organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, iş ortaklarımız ile olan ilişkilerin yönetimi,"+
        " Bahçeşehir Grup Şirketleri politika ve kurallarına uyumluluğun sağlanması → İş Ortakları.  </li>" +
        "<li>	Kişisel verileriniz üçüncü taraflarla paylaşılırken Kanun’un 8. ve 9. maddelerinde belirtilen kurallara uygunluk sağlanmaktadır.</li>" +
        "Kişisel verileriniz, veli bilgilendirme beyan formu ile yetkilendirmeniz durumunda, formda belirttiğiniz kişi/kişiler ile de paylaşılacaktır."+
        "</ul>"+
        "<div class='mb-2'><b> Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örnekleriler</b></div>" +
        "Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
        "açıklanmıştır: <br/>" +
        " <br/>" +
        " <br/>" +
        "<b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br/>" +
        "Sözleşmenin kurulması veya ifası" +
        " <br/><br/>" +
        "<b>- Örneğin, ders seçme/dersten çekilme işlemlerinin yerine getirilmesi.</b>  <br/>" +
        "Bir hakkın tesisi,kullanılması veya korunması" +
        " <br/><br/>" +
        "<b>- Örneğin, üniversite, fakülte ya da öğrenci etkinlikleri hakkında duyuruların yapılması.</b>  <br/>" +
        "Meşru menfaatlerimiz için zorunlu olması" +
        " <br/><br/>" +
        "<b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br/>" +
        "Hukuki yükümlülüklerimiz"+
        "<br/><br/>" +
        "<div class='mb-2'><b> Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
        "Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
        "haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
        "Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
        "aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
        "<ul>" +
        "<li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
        "<li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
        "<li>E-posta yoluyla (1) → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
        "</ul>"+
        "Sistemlerimizde daha önce kayıtlı bir e-posta adresinizin olması ve bu adresi kullanmanız suretiyle.<br><br>"+
        " <br/>" +
        "<div class='mb-2'><b> Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
        "BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br/>" +
        "<ul>"+
        "<li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
        "<li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>"+
        "</ul>",
    graduate_app_info: "<h5>Yüksek Lisans Programına Başvuru Yapacak Adayların Dikkatine</h5>" +
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde bulunan lisansüstü programlara başvurular online başvuru"+
        " üzerinden yapılmaktadır. Adaylar, başvuru sürecini enstitü ile yürütmelidir.</p>"+
        "<p>Başvuru sürecinin ilk aşaması olan online başvuruda öğrencinin en fazla 3 adet programa başvurma hakkı bulunmaktadır."+
        " 3 tercihi de doldurmak zorunda değildir. İlk tercih ettiğiniz programa ek olarak 2 ayrı programa "+
        "daha başvuru yapmak öğrenci inisiyatifindedir.</p>"+
        "Tezsiz yüksek lisans programlarına başvuracak adaylar için ALES puan şartı aranmamaktadır."+
        " Ancak üniversitemizin uyguladığı başarı bursları arasında yer alan başvurduğu bölümün ilgili"+
        " puan türünden ALES 85 ve üzeri puana sahip olan öğrenciler, %20 oranında ALES başarı bursundan faydalanabilirler."+
        "<p>Tezli yüksek lisans programlarına başvuracak adaylar için ALES puan şartı aranmaktadır."+
        " ALES puan kriteri için enstitü ile iletişime geçilmelidir.</p>"+
        "<p>Eğitim dili İngilizce olan programlar için aday öğrencinin TOEFL, YDS, YÖKDİL ve BAU İngilizce Yeterlik "+
        "sınavlarından yeterli puanda ve güncel tarihli sınav sonuçlarının olması şartı aranmaktadır. "+
        "(Puan kriterleri için enstitü ile iletişime geçilmelidir. TOEFL, BAU İngilizce Yeterlik Sınav"+
        " geçerlilik süresi 2 yıl, YDS, YÖKDİL sınavlarının geçerlilik süresi 5 yıldır.)</p>"+
        "<p>Eğitim dili Türkçe olan programlara başvuru için İngilizce yeterlilik belgesi şartı aranmamaktadır."+
        " Ancak yeterli puanda dil yeterlilik sınav sonucu olan öğrenciler,"+
        " üniversitemizin uyguladığı başarı bursları arasında yer alan %10 oranında dil başarı bursundan faydalanabilirler</p>"+
        "<h5>Doktora Programına Başvuru Yapacak Adayların Dikkatine!</h5>" +
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde bulunan lisansüstü programlara başvurular ortak online başvuru üzerinden yapılmaktadır."+
        " Adaylar, başvuru sürecini tercih ettiği programın bağlı olduğu enstitü ile yürütmelidir.</p>"+
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde yer alan doktora programlarına başvuru yapacak olan "+
        "adayların sadece tek program tercih hakkı bulunmaktadır</p>"+
        "<p>Doktora programlarına başvuru aşamasında, adayların tüm şartları sağlamış olması zorunludur."+
        " Doktora başvuru sürecinde özel öğrencilik durumu uygulanmamaktadır.</p>"+
        "<p>Tüm şartları sağlayan adaylar, yazılı bilim sınavına girmeye hak kazanırlar. "+
        "Yazılı sınavdan başarılı olan adaylar, sözlü sınava tabi tutulurlar. "+
        "Programa kabul edilmeleri halinde, kesin kayıt yapma hakkı elde ederler.</p>"+
        "<p><p>Önemli not:</p> 6 Şubat 2013 tarihinde YÖK Lisansüstü Eğitim ve Öğretim Yönetmeliği’nde yapılan "+
        "değişikliklere istinaden doktora programlarına;</p>"+
        "<ul><li><u>Tezli yüksek lisans derecesi ile başvuran adaylarda,</u> ALES notunun ilgili puan türünden "+
        "en az 60 olması, YDS, YÖKDİL puanının en az 65 olması (ya da muadili puan türü) şartı aranmaktadır.</li></ul>"+
        "<p>06.02.2013 tarihinden önce tezsiz yüksek lisans programına kayıt yaptırmış ya da programdan"+
        " mezun olan adaylar tezli yüksek lisans derecesi ile başvuran adaylar ile aynı şartlara tabi tutulacaktır.</p>"+
        "<p>(Bkz: <a href='http://www.resmigazete.gov.tr/eskiler/2013/02/20130206-10.htm'>http://www.resmigazete.gov.tr/eskiler/2013/02/20130206-10.htm</a>)</p>"+
        "<p><b>NOT:</b> Doktora programlarına lisans derecesi ile başvurabilme kriteri enstitüler için değişiklik gösterebilir."+
        " Konu ile alakalı olarak ilgili enstitü ile iletişime geçilmelidir.</p>"+
        "<p><b>NOT:</b> Enstitünün kararlarına göre doktora programlarının ALES ve "+
        "dil yeterlilik başvuru puan kriteri farklılık gösterebilmektedir.</p>",

    graduate_kvkk_content: "<div class='mb-2'>" +
        "            <h2>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Ön Lisans, Lisans Öğrencileri</h2>" +
        "        </div>" +
        "        <br>" +
        "        <div class='text-left'>" +
        "            <div class='mb-2'><b>Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
        "        Bahçeşehir Üniversitesi olarak,  öğrencilerimizin  kişisel verilerinin  nasıl işleneceğini belirliyor  ve  veri " +
        "        kayıt sisteminin yönetimini sağlıyoruz.  Bir diğer ifadeyle, kişisel verilerinizi işlerken veri sorumlusu " +
        "        olarak hareket ediyoruz." +
        "        <br><br>" +
        "        <div class='mb-2'><b>Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor?</b></div>" +
        "        Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:<br><br>" +
        "        − <b>Kişisel verilerinizi toplama kanalları </b>  ➢ Fiziki ortamlarda form doldurmanız veya bilgilerinizi " +
        "        bizimle paylaşmanız; öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital " +
        "        ortamlarda yer alan iletişim formlarını doldurmanız; telefon, e-posta, sosyal medya hesapları " +
        "        veya  BAU  whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar " +
        "        aracılığıyla kişisel verileriniz toplanmaktadır. <br><br>" +
        "        − <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢ Kişisel verileriniz, kısmen otomatik,  otomatik  veya " +
        "        veri kayıt sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.<br><br>" +
        "        − <b>Kişisel verilerinizin toplanmasının hukuki sebepleri</b> ➢ Kişisel verileriniz, Kanun’un 5. " +
        "        maddesinde belirtilen hukuki sebeplerden hukuki yükümlülüklerimizin yerine getirilmesi, " +
        "        sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin " +
        "        zorunlu olması, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri " +
        "        işlenmesinin meşru menfaatlerimiz için zorunlu olması hukuki sebeplerine dayalı olarak " +
        "        işlenmektedir.<br>" +
        "         <br>" +
        "        <div class='mb-2'><b>Hangi kişisel verilerim işleniyor?</b></div>" +
        "        BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br>" +
        "        <div class='mt-2'>" +
        "        <table class='table b-table table-striped table-bordered'>" +
        "        <tr>" +
        "            <th>Kimlik</th>" +
        "            <th>İletişim</th>" +
        "            <th>Eğitim</th>" +
        "            <th>Diğer Bilgileriniz</th>" +
        "        </tr>" +
        "        <tr>" +
        "            <td>Ad-soyad, kimlik numarası, cinsiyet, uyruk, doğum yeri ve tarihi, kimlik fotokopisi, öğrenci numarası, uluslararası öğrencilerimizin pasaport bilgileri</td>" +
        "            <td>Telefon numarası, eposta adresi, iş ve ev adresi</td>" +
        "            <td>Fakülte, bölüm, sınıf, staj bilgileri, danışman bilgisi, proje, ödev, sunum, tez vb. eğitim materyalleri, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, transkript, alınan dersler, hazırlık okuma bilgisi, ÖSYM yerleşme ve puan bilgileri, yatay geçiş bilgisi, yan dal/çift ana dal bilgisi, kredi bilgileri, mezun olunan okul bilgileri</td>" +
        "            <td>Fotoğraf, meslek bilgisi, banka bilgileri, öğrenci işlem kayıtları, IBAN, gelir bilgisi, disiplin işlemleri bilgileri, işlem güvenliği bilgileri, imza, mazeret nedeni, ayrılma/kayıt dondurma nedeni, kayıt, mezuniyet ve ayrılma tarihleri, yakınlarınızın kimlik ve iletişim bilgileri, anket çalışmaları neticesinde elde edilen bilgiler.</td>" +
        "        </tr>" +
        "        </table>" +
        "        </div>" +
        "        Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin, mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ve engelli öğrencilerimizin öğrencilerimizin <b>ayrıca sağlık verilerini</b> de işliyoruz. Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin <b>adli sicil kaydına ilişkin bilgilerini</b> de işliyoruz.  <br><br>" +
        "        <div class='mb-2'><b>Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
        "        Kişisel Verilerin Korunması Kanunu’nun ('Kanun')"+
"5.maddesi, kişisel verilerin işlenmesine ilişkin hukuki"+
"        sebepleri düzenlemektedir.  Kişisel verilerinizi  bu madde kapsamında, aşağıda belirtilen amaçlar  ve" +
"        hukuki sebeplere uygun olarak la yurtiçinde veya yurtdışında işliyoruz: <br><br>" +
"        <hr>" +
"        <div><b>Sözleşmenin kurulması veya ifası</b></div>" +
"        <div>" +
"            Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, Öğrenci kayıt sözleşmesinin imzalanması; ön lisans, lisans programları kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması, Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi, BAU Öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi, Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması, Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması, Öğrenci zorunlu-gönüllü staj işlemlerinin yürütülmesi, Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması, Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi, <br><br><hr><br> <br> " +
"        </div>" +
"        <div class='col-12 d-flex align-items-center'><b>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</b></div>" +
"        <div>" +
"            Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de dahil olmak " +
"            üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi, Öğrencilerimizin yetenek" +
"             ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin alınması ve sonuçlandırılması, " +
"             Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi, Faaliyetlerimizin iyileştirilmesine ilişkin " +
"             çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması, Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına " +
"             ilişkin süreçlerin yürütülmesi; bu kapsamda, öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe " +
"             süreçlerinin yürütülmesi,<br><br><hr><br>" +
"        </div>" +
"        <div class='col-4 d-flex align-items-center'><b>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</b></div>" +
"        <div>" +
"            Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, yüksek lisans, burs ve " +
"            benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda haklarımızın korunabilmesi, kullanılabilmesi ve yasal " +
"            süreçlerin yürütülmesi.<br><br><hr><br>" +
"        </div>" +
"        <div class='col-4 d-flex align-items-center'><b>Veri işlemenin Kanunlarda açıkça öngörülmesi ve hukuki yükümlülüklerimizin yerine getirilmesi</b></div>" +
"        <div>" +
"            Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili kurum veya kuruluşların " +
"            Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü durumlarda, yasal yükümlülüklerimizin yerine " +
"            getirilmesi. mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi, öğrencinin pilotaj bölümüne kayıt için uygunluğunun tespit " +
"            edilmesi ve yetkili kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir<br><br>" +
"        </div>" +
"        <div class=\"page-number\">2/3</div>" +
"        <div><b>İlgili kişinin açık rızasının bulunması</b></div>" +
"        <div>" +
"            Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin sağlık verileri, sağlık durumunun kontrolü, sağlık durumunun bölüm yeterliliğini " +
"            karşılayıp karşılamadığının tespiti, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi " +
"            amaçlarıyla işlenecektir.<br><br><hr><br>" +
"        </div>" +
"        <div><b>Üniversite Kayıt İşlemleri</b></div>" +
"        <div>Üniversite kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, oryantasyon aktivitelerinin gerçekleştirilmesi <br><br><hr><br>" +
"        </div>" +
"        <div><b>Eğitim ve Öğretim Faaliyetleri</b></div>" +
"        <div>" +
"            Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, öğrencilerimizin yetenek ve " +
"            kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve sosyal gelişimlerinin takibi, ölçme ve değerlendirme " +
"            süreçlerinin yürütülmesi, zorunlu-gönüllü staj işlemlerinin yürütülmesi.<br><br><hr><br>" +
"        </div>" +
"        <div><b>Organizasyon ve Etkinlikler</b></div>" +
"        <div>" +
"            Organizasyon, fuar, yarışma ve etkinliklerin 4 düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi<br><br><hr><br>" +
"        </div>" +
"        <div><b>Diğer Faaliyetlerimizdeki Amaçlar</b></div>" +
"        <div>" +
"            İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama kullanıcı hesaplarının " +
"            oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması, anket çalışmalarının yürütülmesi, faaliyetlerimizin " +
"            denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi, faaliyetlerimizin mevzuata uygun olarak yürütülmesi, " +
"            yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe süreçlerinin yürütülmesi.<br><br><hr><br>" +
"        </div> " +
"        <div class='mb-2'><b>Kişisel verilerim in aktarıldığı taraflar ve aktarılma amaçları nelerdirbaşkalarına aktarılıyor mu?</b></div>" +
"        Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir: <br>" +
"        <ul>" +
"        <li>Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki yükümlülüklerimizin " +
"            yerine getirilmesi → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
"        <li>Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın" +
"         devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.  </li>" +
"        </ul>" +
"        Kişisel verileriniz, veli bilgilendirme beyan formu ile yetkilendirmeniz durumunda, formda belirttiğiniz" +
"    kişi/kişiler ile de paylaşılacaktır<br>" +
"        <div class='mb-2'><b>Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örnekleri</b></div>" +
"        Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
"        açıklanmıştır:" +
"        <br>" +
"         <br>" +
"         <br>" +
"        <b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br>" +
"        Sözleşmenin kurulması veya ifası" +
"         <br><br>" +
"        <b>- Örneğin, ders seçme/dersten çekilmeişlemlerinin yerine getirilmesi.</b>  <br>" +
"        Bir hakkın tesisi,kullanılması veya korunması" +
"         <br><br>" +
"        <b>- Örneğin, üniversite, fakülte ya da öğrencietkinlikleri hakkında duyuruların yapılması.</b>  <br>" +
"        Meşru menfaatlerimiz için zorunlu olması" +
"         <br><br>" +
"        <b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br>" +
"        Hukuki yükümlülüklerimiz" +
"        <br><br>" +
"        <div class='mb-2'><b>Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
"        Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
"        haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
"        Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
"        aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
"        <ul>" +
"        <li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
"        <li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
"        <li>E-posta yoluyla1 → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
"        </ul>" +
"         <br>" +
"        <div class='mb-2'><b>Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
"        BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br>" +
"        <ul>" +
"        <li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
"        <li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>" +
"        </ul>",
    course_transfer_form_info: " İntibak belgenizi/belgelerinizi yükledikten sonra \"Talebi Gönder\" butonuna tıklayarak talebinizi oluşturursunuz. İntibak işlemleriniz tamamlandığında tarafınıza bilgi verilecektir. “Taleplerim” ekranında “İntibak” sekmesine giderek intibak işleminizi onaylamanız gerekmektedir. Transkripti Bahçeşehir Üniversitesine ait olan öğrencilerin transkriptleri sistem tarafından otomatik yüklenir. Detaylı bilgi için <a href=\"#\" class='text-primary' download>Kullanım Kılavuzuna</a> göz atınız.",
    conservatory_app_approve_text: "Burada yapılan ön kayıt işlemi öncesinde asıl kayıt için gerekli şartları sağladığınızdan emin olunuz. Burada sınav için yaptığınız ön kayıt, sınava girdikten sonra başarılı olsanız dahi, asıl kayıt için gerekli şartları yerine getiremediğinizde geçersiz olacaktır.",
    conservatory_app_aprove_multimedia_text: "Konservatuar başvuruları kapsamında Bahçeşehir Üniversitesi’ne ileteceğiniz ses, görüntü, fotoğraf ve video kayıtlarınıza ilişkin aşağıdaki muvafakatnameyi onaylayabilirsiniz.\n" +
        "Bahçeşehir Üniversitesi’nin paylaşacağım ses, görüntü, fotoğraf ve/veya video kayıtlarını konservatuar başvuruları kapsamında yeterlilik ve yetenek değerlendirmesi için kullanmasını kabul ediyorum.",
    fashion_design_app_approve_text: "Burada yapılan ön kayıt işlemi öncesinde asıl kayıt için gerekli şartları sağladığınızdan emin olunuz. Burada sınav için yaptığınız ön kayıt, sınava girdikten sonra başarılı olsanız dahi, asıl kayıt için gerekli şartları yerine getiremediğinizde geçersiz olacaktır.",
    fashion_design_app_aprove_multimedia_text: "Tekstil ve Moda Tasarımı başvuruları kapsamında Bahçeşehir Üniversitesi’ne ileteceğiniz ses, görüntü, fotoğraf ve video kayıtlarınıza ilişkin aşağıdaki muvafakatnameyi onaylayabilirsiniz. Bahçeşehir Üniversitesi’nin paylaşacağım ses, görüntü, fotoğraf ve/veya video kayıtlarını Tekstil ve Moda Tasarımı başvuruları kapsamında yeterlilik ve yetenek değerlendirmesi için kullanmasını kabul ediyorum.",

}

export default translations


