const routes = [
    {
        path: "/constants",
        component: () => import("../pages/Index"),
        meta:{
            isAuthenticated: true,
            permission:"constant_index"
        }
    },
    {
        path: "/constants/download/file/:key/:filename",
        component: () => import("../pages/DownloadFile"),
        meta:{
            isAuthenticated: true,
        }
    }
]

export default routes;
