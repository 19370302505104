import { ValidationProvider, ValidationObserver } from "vee-validate";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import ProgramLevelSelectboxLYL from "@/components/interactive-fields/ProgramLevelSelectboxLYL";
import ProgramLevelSelectboxOLLYLD from "@/components/interactive-fields/ProgramLevelSelectboxOLLYLD";
import ProgramLevelSelectbox from "@/components/interactive-fields/ProgramLevelSelectbox";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox.vue";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
import UnihallQuotaSelectbox from "@/components/interactive-fields/UnihallQuotaSelectbox";
import UnihallQuotaPriceSelectbox from "@/components/interactive-fields/UnihallQuotaPriceSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ClassesSelectbox from "@/components/interactive-fields/ClassesSelectbox.vue";
import Loading from '@/components/elements/Loading'
import ScholarshipRateSelectbox from '@/components/interactive-fields/ScholarshipRateSelectbox'
import HeaderActionButton from '@/layouts/AppLayout/HeaderActionButton'
import RoleSelectbox from '@/components/interactive-fields/RoleSelectbox';
import GradeSystemSelectbox from "@/components/interactive-fields/GradeSystemSelectbox";
import PermissionSelectbox from "@/components/interactive-fields/PermissionSelectbox";
import ModelListSelectbox from "@/components/interactive-fields/ModelListSelectbox";
import Pagination from "@/components/page/Pagination";
import LanguageSelectbox from '@/components/interactive-fields/LanguageSelectbox';
import NotificationDropdown from '@/components/page/NotificationDropdown';


export default ({ Vue }) => {
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("SemestersSelectbox", SemestersSelectbox);
    Vue.component("ParameterSelectbox", ParameterSelectbox);
    Vue.component("CourseAutoComplete", CourseAutoComplete);
    Vue.component("StaffAutoComplete", StaffAutoComplete);
    Vue.component("FacultySelectbox", FacultySelectbox);
    Vue.component("AppLayout", AppLayout);
    Vue.component("Header", Header);
    Vue.component("HeaderMobile", HeaderMobile);
    Vue.component("DatatableFilter", DatatableFilter);
    Vue.component("Datatable", Datatable);
    Vue.component("CommonModal", CommonModal);
    Vue.component("DepartmentSelectbox", DepartmentSelectbox);
    Vue.component("ProgramSelectbox", ProgramSelectbox);
    Vue.component("SelectDate", SelectDate);
    Vue.component("UniversitySelectbox", UniversitySelectbox);
    Vue.component("CountrySelectbox", CountrySelectbox);
    Vue.component("ProgramLevelSelectboxLYL", ProgramLevelSelectboxLYL);
    Vue.component("ProgramLevelSelectboxOLLYLD", ProgramLevelSelectboxOLLYLD);
    Vue.component("ProgramLevelSelectbox", ProgramLevelSelectbox);
    Vue.component("YesNoSelectbox", YesNoSelectbox);
    Vue.component("TrueFalseSelectbox", TrueFalseSelectbox);
    Vue.component("AcademicYearsSelectbox", AcademicYearsSelectbox);
    Vue.component("GenderSelectbox", GenderSelectbox);
    Vue.component("UnihallQuotaSelectbox", UnihallQuotaSelectbox);
    Vue.component("UnihallQuotaPriceSelectbox", UnihallQuotaPriceSelectbox);
    Vue.component("MultiSelectbox", MultiSelectbox);
    Vue.component("ClassesSelectbox", ClassesSelectbox);
    Vue.component("Loading", Loading);
    Vue.component("ScholarshipRateSelectbox", ScholarshipRateSelectbox);
    Vue.component("HeaderActionButton", HeaderActionButton);
    Vue.component("RoleSelectbox", RoleSelectbox);
    Vue.component("GradeSystemSelectbox", GradeSystemSelectbox);
    Vue.component("PermissionSelectbox", PermissionSelectbox);
    Vue.component("ModelListSelectbox", ModelListSelectbox);
    Vue.component("Loading", Loading);
    Vue.component("Pagination", Pagination);
    Vue.component("LanguageSelectbox", LanguageSelectbox);
    Vue.component("NotificationDropdown", NotificationDropdown);
};
