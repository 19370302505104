const routes = [
    {
        path: "/grade/conversions/define",
        component: () => import("../pages/defineForm"),
        meta: {
            isAuthenticated: true,
            permission: "gradeconversion_store"
        }
    },

    {
        path: "/grade/conversions",
        component: () => import("../pages/gradeConversionTable"),
        meta: {
            isAuthenticated: true,
            permission: "gradeconversion_index"
        }
    },

    {
        path: "/grade/conversions/update/:id",
        component: () => import("../pages/updateForm"),
        meta: {
            isAuthenticated: true,
            permission: "gradeconversion_update"
        }
    },

];

export default routes;
