const routes = [
    {
        path: "/surveys",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "survey_index"
        }
    },
    {
        path: "/surveys/:id/questions",
        component: () => import("../pages/Questions"),
        meta: {
            isAuthenticated: true,
            permission: "survey_show"
        }
    },
    {
        path: "/surveys/:id/evaluate/:related_id",
        component: () => import("../pages/Evaluate"),
        meta: {
            isAuthenticated: true,
            isSurvey: true
        }
    },
    {
        path: "/survey/results/:surveyId",
        component: () => import("../pages/Results"),
        meta: {
            isAuthenticated: true,
            permission: "survey_index"
        }
    },
    {
        path: "/survey/instructor/results",
        component: () => import("../pages/InstructorResults"),
        meta: {
            isAuthenticated: true,
            permission: "survey_instructorlist"
        }
    },
];

export default routes;
