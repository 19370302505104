<template>
	<div>
		<multiselect v-model="selected" :options="options" label="text" track-by="value"
			:class="validateError != '' ? 'box-border-color' : ''" :placeholder="$t('select')" :multiple="multiple"
			:close-on-select="!multiple" :clear-on-select="!multiple" :select-label="''" :selected-label="''"
			:deselect-label="''" @input="handleInput($event, multiple, multipleReturnType)">
			<template slot="selection" slot-scope="{ values, isOpen }">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
			</template>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
	</div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

export default {
	props: {
		value: {
			type: [String, Array],
			default: null,
		},
		validateError: {
			type: String,
			default: '',
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		multipleReturnType: {
			type: String,
			default: 'withComma',
		},
		noOL: {
			type: Boolean,
			default: false
		},
		noD: {
			type: Boolean,
			default: false
		}
	},
	created() {
        this.loadData()
		this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
	},
	data() {
		return {
			selected: null,
			options: []
		}
	},
	watch: {
		value: function (newValue) {
			this.selected = this.setSelected(newValue, this.options, this.multiple, this.multipleReturnType);
		}
	},
	methods: {
		translateNSelected: translateNSelected,
		setSelected: setSelected,
		handleInput: handleInput,

        loadData(){
            if(this.noOL == false) {
                this.options.push({value: "OL", text: this.$t("program_level_ol")})
                this.options.push({value: "L", text: this.$t("program_level_l")})
                this.options.push({value: "YL", text: this.$t("program_level_yl")})
            } else {

                this.options.push({ value: "L", text: this.$t("program_level_ol") + '/' + this.$t("program_level_l") })
            }

            if(this.noD == false){
                this.options.push({ value: "D", text: this.$t("program_level_d") })
            } else {
                this.options.push({ value: "YL", text: this.$t("program_level_yl") + '/' + this.$t("program_level_d") })
            }

        }
	}
}
</script>
