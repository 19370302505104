<template>
    <header class="header fixed-top">
        <b-navbar class="p-0">
            <b-navbar-brand class="d-flex align-items-center justify-content-center collapsed-logo desktop-logo"
                :class="[{ 'expanded-logo': sideBarExpandedWrapper }]">
                <div class="logo-wrapper">
                    <!-- <router-link to="/dashboard">
                        <img src="../../assets/img/umis-logo.png" />
                    </router-link> -->
                    <router-link to="/dashboard">
                        <img v-show="$store.getters['dashboard/getImage']('menu-logo.png')"
                            :src="$store.getters['dashboard/getImage']('menu-logo.png')" />
                    </router-link>
                </div>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-collapse id="nav-collapse" is-nav class="d-block">
                    <div class="mb-0 ml-5 bg-transparent small text-uppercase d-none d-lg-inline-flex p-0">
                        <h5 class="m-0 mr-5 d-flex align-items-center" v-if="title">
                            {{ title.toUpper() }}
                        </h5>

                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="$emit('new-button-click')" v-if="isNewButton">
                            <span class="d-flex">
                                <i class="ri-add-line mr-1"></i>
                                {{ $t('new').toUpper() }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="$emit('rank-button-click')" v-if="isRankButton">
                            <span>
                                <i class="ri-sort-desc top-plus-1 mr-1"></i>
                                {{ $t('ranking').toUpper() }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="$emit('import-button-click')" v-if="isImportButton">
                            <span class="d-flex">
                                <i class="ri-upload-line mr-1"></i>
                                {{ $t('import_button').toUpper() }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="otherButton.emit ? $emit(otherButton.emit) : ''" v-if="otherButton.show">
                            <span class="d-flex">
                                <i :class="otherButton.icon ? otherButton.icon + ' mr-1' : ''"
                                    v-if="otherButton.icon"></i>
                                <span v-if="otherButton.title">{{ otherButton.title }}</span>
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="asalButton.emit ? $emit(asalButton.emit) : ''"
                            v-if="asalButton.show && selectedMenu == 'military_information'">
                            <span class="d-flex">
                                <i :class="asalButton.icon ? asalButton.icon + ' mr-1' : ''" v-if="asalButton.icon"></i>
                                <span v-if="asalButton.title">{{ asalButton.title }}</span>
                            </span>
                        </b-button>
                        <b-dropdown variant="outline-secondary secondary-border-light" class="mr-2" v-if="isColumns">
                            <template #button-content>
                                <span class="d-flex">
                                    <i class="ri-layout-column-line mr-1"></i>
                                    {{ $t('columns').toUpper() }}
                                </span>
                            </template>
                            <div class="checkbox-list width-300">
                                <div class="px-4 py-2">
                                    <slot name="columns"></slot>
                                </div>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                            @click="filterDivStatusHide" v-if="isFilter">
                            <span>
                                <i class="ri-filter-3-line mr-1"></i>
                                {{ $t('filter').toUpper() }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light w-40" class="mr-2"
                            @click="$emit('expand-all')" v-if="isExpandAll">
                            <span class="d-flex">
                                <i class="ri-arrow-down-s-line font-size-20"></i>
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light w-40" class="mr-2"
                            @click="$emit('collapse-all')" v-if="isCollapseAll">
                            <span class="d-flex">
                                <i class="ri-arrow-up-s-line font-size-20"></i>
                            </span>
                        </b-button>
                        <b-button variant="danger" class="mr-2" @click="$emit('move-cancel')" v-if="isCancelToMove">
                            <span class="d-flex">
                                <i class="ri-close-line font-size-20"></i> {{ $t('cancel_to_move') }}
                            </span>
                        </b-button>

                        <header-action-button :actions="filteredActions">
                        </header-action-button>
                        <b-button variant="outline-secondary secondary-border-light" class="width-40 mr-2"
                            @click="backButton" v-if="isBackButton">
                            <i class="ri-arrow-left-line"></i>
                        </b-button>
                        <template v-for="(button, index) in actionButtons">
                            <b-button
                                :variant="button.variant ? button.variant : 'outline-secondary secondary-border-light'"
                                :key="`action-button-${index}`" class="mr-2" :class="button.class ? button.class : ''"
                                @click="button.callback">
                                <i v-if="button.icon" :class="button.icon"></i>
                                {{ button.text }}
                            </b-button>
                        </template>
                    </div>
                    <b-navbar-nav class="ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5">
                        <!--
                        <b-button class="btn-40 d-none d-lg-flex" :class="{'btn-dark':nightMode}" variant="outline-secondary" @click="changeNightMode">
                            <i class="ri-moon-line left-plus-1 position-relative"></i>
                        </b-button>
                        -->
                        <div class="notification d-flex position-relative">
                            <b-button class="btn-40 d-block d-lg-none" variant="outline-secondary"
                                @click="changeSideBarExpandedWrapper">
                                <i class="ri-menu-2-line"></i>
                            </b-button>
                            <NotificationDropdown />


                            <header-action-button class="ml-5 d-inline-flex d-lg-none" :actions="filteredActions">
                            </header-action-button>
                        </div>
                        <b-navbar-brand class="d-flex align-items-center collapsed-logo d-lg-none navbar-md">
                            <div class="logo-wrapper">
                                <router-link to="/dashboard">

                                    <img src="../../assets/img/umis-logo.png" />
                                </router-link>
                            </div>
                        </b-navbar-brand>
                        <div class="d-flex">
                            <!--
                            <b-navbar-toggle target="nav-mobil-search"
                                             class="btn btn-40 d-block d-lg-none btn-outline-secondary">
                                <template>
                                    <i class="ri-search-line"></i>
                                </template>
                            </b-navbar-toggle>
                            -->
                            <b-button class="btn width-80 mr-2" :variant="timerBtnVariant"
                                :title="$t('session_timer_title')"
                                @click="callTokenClick">
                                <i class="ri-timer-line"></i> {{ timerOutput }}
                            </b-button>

                            <b-button class="btn-40 d-lg-flex" variant="outline-secondary mr-2" @click="print"
                                v-if="isPrint">
                                <i class="ri-printer-line"></i>
                            </b-button>

                            <b-button class="btn" variant="outline-secondary mr-2"
                                v-if="user?.active_role?.name == 'OGR'">
                                <div class="d-flex flex-column">
                                    <span style="margin-bottom: -20px;font-weight: bold">{{ user.full_name }}</span>
                                    <span style="margin-top:-5px;font-weight: bold" v-if="locale == 'tr'"> {{
                    user.active_student_program.program_name }}</span>
                                    <span style="margin-top:-5px;font-weight: bold" v-else> {{
                    user.active_student_program.program_name_en }}</span>
                                </div>
                            </b-button>

                            <b-button class="btn p-1 header-user-info-btn d-none d-md-block"
                                variant="outline-secondary mr-2" v-else>
                                {{ user.full_name }} {{ getLocaleText(user.active_role, 'explanation') }}
                            </b-button>


                            <b-button class="btn-40 d-lg-flex mr-2" variant="outline-secondary"
                                @click="changeLocale('en')" v-if="locale == 'tr'">
                                EN
                            </b-button>
                            <b-button class="btn-40 d-lg-flex mr-2" variant="outline-secondary"
                                @click="changeLocale('tr')" v-else>
                                TR
                            </b-button>
                            <b-dropdown id="dropdown-form" ref="dropdown" variant="link" class="no-btn" right no-caret>
                                <template #button-content>
                                    <div class="wh-40-img rounded bg-cover-center"
                                        :style="{ 'background-image': 'url(' + photo + ')' }" v-if="photo"></div>
                                    <div class="wh-40-img rounded bg-cover-center"
                                        v-bind:style="{ 'background-image': 'url(' + require('@/assets/img/no-photo-2.jpg') + ')' }"
                                        v-else></div>
                                </template>

                                <b-dropdown-item to="/my-profile" class="d-block d-md-none">
                                    <i class="ri-user-4-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ user.full_name }} {{ getLocaleText(user.active_role, 'explanation') }}
                                </b-dropdown-item>

                                <b-dropdown-item to="/my-profile" v-if="isStudent">
                                    <i class="ri-user-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('personal_info') }}
                                </b-dropdown-item>

                                <!--
                                <b-dropdown-item>
                                    <i class="ri-arrow-left-right-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('switch_user') }}
                                </b-dropdown-item>
                                -->

                                <!-- Roles -->
                                <b-dropdown-item v-if="roles && roles.length > 1"
                                    @click="$bvModal.show('otherIdentiesModal')">
                                    <i class="ri-profile-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('other_identies') }}
                                </b-dropdown-item>

                                <!-- Programs -->
                                <b-dropdown-item v-if="programs && programs.length > 1"
                                    @click="$bvModal.show('otherProgramsModal')">
                                    <i class="ri-profile-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('other_programs') }}
                                </b-dropdown-item>

                                <b-dropdown-item @click="changePasswordModalShow()">
                                    <i class="ri-key-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('change_your_password') }}
                                </b-dropdown-item>

                                <div class="d-flex flex-row px-2 py-2" style="cursor: pointer"
                                    v-if="twoFactor?.two_factor !== undefined">
                                    <b-form-checkbox :disabled="twoFactorForced" v-model="twoFactor.two_factor" switch>
                                        <!-- {{ two_factor ? $t('open') : $t('closed') }} -->
                                    </b-form-checkbox>
                                    <div @click="$refs.twoFactorModal.$refs.commonModal.show()">
                                        <i class="mr-1 top-minus-1 align-middle text-muted"
                                            :class="twoFactor.two_factor ? 'ri-lock-line' : 'ri-lock-unlock-line'"></i>
                                        {{ $t('two_factor_authentication') }}
                                    </div>
                                </div>
                                <!-- <b-dropdown-item disabled>
                                </b-dropdown-item> -->

                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item to="/logout">
                                    <span class="text-danger">
                                        <i class="ri-logout-circle-line mr-3 top-minus-1 align-middle"></i>
                                        {{ $t('logout') }}
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-collapse>
        </b-navbar>
        <CommonModal id="otherIdentiesModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('other_identies').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="list-group">
                    <template v-for="(role, index) in roles">
                        <a href="#" class="list-group-item list-group-item-action" :key="index"
                            :class="roleIsActive(role, activeRole) ? 'active' : ''" v-if="roles && roles.length > 1"
                            @click="changeRole(role)">
                            {{ getLocaleText(role, 'explanation').trim() }}
                            <b-icon icon="star-fill" class="float-right" aria-hidden="true"
                                @click="defaultRoleChange($event, role)"
                                :class="role.default ? 'default-active-role-star' : 'default-role-star'"></b-icon>

                        </a>
                    </template>
                </div>
            </template>
        </CommonModal>
        <CommonModal id="otherProgramsModal" size="md">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('other_programs').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="list-group">
                    <template v-for="(program, index) in programs">
                        <a href="#" :key="index"
                            :class="'list-group-item list-group-item-action' + (program.id == activeProgram.id ? ' active' : '')"
                            v-if="programs && programs.length > 1" @click="changeProgram(program.id)">
                            {{ getLocaleText(program, 'program_name') }}
                        </a>
                    </template>
                </div>
            </template>
        </CommonModal>
        <CommonModal id="changePasswordModal" :onHideOnlyX="true" @bHideModalHeaderClose="changePasswordModalClose">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('change_your_password').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <change-password v-if="formProcess == 'change-password'" />
            </template>
        </CommonModal>
        <CommonModal ref="twoFactorModal" size="md" :onHideOnlyX="true"
            @bHideModalHeaderClose="$store.state.user.notificationModalValue = false">
            <template v-slot:CommonModalTitle>
                <template>
                    {{ $t('two_factor_authentication').toUpper() }}
                </template>
            </template>
            <template v-slot:CommonModalContent>
                <b-row class="">
                    <b-col cols="12" class="d-flex flex-row align-items-center">
                        <i class="ri-checkbox-circle-line mr-2" :style="'font-size: 26px;'" :class="'text-success'"
                            v-if="twoFactor?.two_factor_with_sms"></i>
                        <i class="ri-close-line mr-2" :style="'font-size: 26px;'" :class="'text-danger'" v-else></i>
                        <span>
                            {{ $t('verification_via_sms') }}
                        </span>
                        <b-button :variant="twoFactor?.two_factor_with_sms ? 'success' : 'danger'"
                            class="ml-auto mw-100px" @click="changeTwoFactorStatus">
                            {{ twoFactor?.two_factor_with_sms ? $t('open') : $t('closed') }}
                        </b-button>
                    </b-col>
                    <b-col cols="12" class="d-flex flex-row align-items-center mt-4">
                        <i class="ri-checkbox-circle-line mr-2" :style="'font-size: 26px;'" :class="'text-success'"
                            v-if="twoFactor?.two_factor_with_authenticator"></i>
                        <i class="ri-close-line mr-2" :style="'font-size: 26px;'" :class="'text-danger'" v-else></i>
                        <span>
                            {{ $t('verification_with_authenticator_app') }}
                        </span>
                        <b-button :variant="twoFactor?.two_factor_with_authenticator ? 'success' : 'danger'"
                            class="ml-auto mw-100px" @click="createTwoFactor">
                            {{ twoFactor?.two_factor_with_authenticator ? $t('open') : $t('closed') }}
                        </b-button>
                    </b-col>
                    <template v-if="two_factor_create_qr">
                        <b-col cols="12" class="">
                            <div v-html="two_factor_create_qr"></div>
                        </b-col>
                        <b-col cols="12" class="">
                            {{ $t('two_factor_app_info') }}
                        </b-col>
                        <b-col cols="12">
                            <ValidationObserver ref="formTwoFactorAppCode">
                                <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('code')">
                                        <b-form-input :validate-error="errors[0]" v-model="two_factor_code"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-col>

                        <b-col cols="12" class="d-flex flex-row mt-4">
                            <b-button variant="primary" class="" @click="confirmTwoFactor">
                                {{ $t('approve') }}
                            </b-button>
                        </b-col>
                    </template>


                </b-row>
            </template>
        </CommonModal>
    </header>
</template>
<script>

// Component
import ChangePassword from '@/modules/auth/pages/ChangePassword';
import CommonModal from '@/components/elements/CommonModal';

// Services
import UserService from '@/services/UserService';
import AuthService from "@/services/AuthService";


// Other
import { mapGetters } from "vuex"
import { localeChanged } from 'vee-validate'
import Base from "@/plugins/Base"
import { EventBus } from "@/main"


export default {
    components: {
        ChangePassword,
        CommonModal
    },
    props: {
        selectedMenu: {},
        title: {
            type: String,
            default: null
        },
        title2: {},
        massAbsenceUpdate: {
            type: Boolean,
            default: false,
        },
        isNewButton: {
            type: Boolean,
            default: false
        },
        isRankButton: {
            type: Boolean,
            default: false
        },
        isImportButton: {
            type: Boolean,
            default: false
        },
        isColumns: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: true
        },
        isExpandAll: {
            type: Boolean,
            default: false
        },
        isCollapseAll: {
            type: Boolean,
            default: false
        },
        isCancelToMove: {
            type: Boolean,
            default: false
        },
        asalButton: {
            type: Object,
            default: () => ({
                selected: null,
                show: false,
                icon: null,
                title: null,
                emit: null
            })
        },
        otherButton: {
            type: Object,
            default: () => ({
                show: false,
                icon: null,
                title: null,
                emit: null
            })
        },
        actions: {
            type: Array,
            default: null
        },
        actionButtons: {
            type: Array,
            default: () => []
        },
        isBackButton: {
            type: Boolean,
            default: false
        },
        isPrint: {
            type: Boolean,
            default: false
        },
        backButton: {
            type: Function,
            default: function () {

            }
        }
    },
    data() {
        return {
            userPhoto: null,

            // Filter
            filterDivStatus: true,

            // Form Process
            formProcess: null,
            two_factor_create_qr: "",
            two_factor_code: "",
            countDownToTime: Base.LocalStorage.get('expire_in') * 1000,
            timerOutput: null,
            timerBtnVariant: 'outline-secondary',
            timerDangerMin: 15*60
        }
    },
    watch: {
        timerOutput: {
            handler (value) {
                let splitData = value.split(':')
                let timeInSec = (parseInt(splitData[0]) * 60)+parseInt(splitData[1])
                if(timeInSec < this.timerDangerMin) {
                    this.timerBtnVariant = 'outline-danger'
                }
                else {
                    this.timerBtnVariant = 'outline-secondary'
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            twoFactor: "user/getTwoFactor"
        }),
        // Locale
        twoFactorForced() {
            if (this.twoFactor && this.twoFactor?.is_two_factor_forced) {
                return true
            }
            return false
        },
        locale() {
            return this.$store.getters['getLocale']
        },

        // Notifications
        notifications() {
            return this.$store.getters['auth/getNotifications']
        },

        // Layout
        sideBarExpandedWrapper() {
            return this.$store.getters.getAppLayoutSideBarExpandedWrapper;
        },
        nightMode() {
            return this.$store.getters.getNightMode;
        },

        // User
        user() {
            return this.$store.getters['auth/getUser'];
        },

        // Photo
        photo() {
            let photo = this.$store.getters['auth/getPhoto'];
            if (photo) {
                return '\'' + photo + '\'';
            }
            return null;
        },

        // Student
        isStudent() {
            return this.$store.getters['auth/isStudent'];
        },

        // Roles
        roles() {
            let user = this.$store.getters['auth/getUser'];
            if (user && user.roles && user.roles.length > 1 && user.person && user.person.type == 'staff') {
                return user.roles;
            }
            return []
        },
        activeRole() {
            let user = this.$store.getters['auth/getUser'];
            if (user && user.active_role) {
                return user.active_role;
            }
            return null
        },

        // Programs
        programs() {
            let user = this.$store.getters['auth/getUser'];
            if (user && user.student && user.student.student_program) {
                return user.student.student_program
            }
            return []
        },
        activeProgram() {
            let user = this.$store.getters['auth/getUser'];
            if (user && user.active_student_program) {
                return user.active_student_program
            }
            return null
        },

        // Filtered Actions
        filteredActions() {
            let arr = [];

            this.actions?.forEach(item => {
                let add = false;
                if (!item.permission || (item.permission && this.checkPermission(item.permission))) {
                    add = true;
                }
                if (add && typeof item.show == 'function') {
                    add = item.show();
                }
                if (add) {
                    arr.push(item);
                }
            })
            return arr;
        }
    },
    methods: {
        async defaultRoleChange($event, role) {
            $event.stopPropagation();
            const data = {
                role_id: role.id,
                faculty_code: role.faculty_code,
                department_code: role.department_code,
                program_code: role.program_code,
            }
            const response = await AuthService.setDefaultRole(data).catch((e => this.showErrors(e)))
            if (response.data.success) {
                this.$toast.success(this.$t('default_role_changed'))
                this.changeRole(role)
                return
            }

        },
        roleIsActive(role, activeRole) {
            if (role.name == activeRole.name && role.faculty_code == activeRole.faculty_code &&
                role.department_code == activeRole.department_code && role.program_code == activeRole.program_code) {
                return true
            }
            return false
        },
        roleSort(roles) {
            const name = `explanation${this.$i18n.locale === "en" ? '_en' : ''}`
            return roles.sort((a, b) => a[name].localeCompare(b[name]))
        },
        // Template
        changeLocale(locale) {
            this.$i18n.locale = locale;
            this.$store.commit('setLocale', locale)
            localeChanged();
        },
        changeNightMode() {
            this.$store.commit('changeNightMode');
        },
        changeSideBarExpandedWrapper() {
            this.$store.commit('changeAppLayoutSidebarExpandedWrapper');
        },

        // Role
        changeRole(role) {
            if (parseInt(role.id) > 0) {
                UserService.changeRole(role)
                    .then(() => {
                        UserService.usersMe()
                            .then(responseUser => {
                                let data = responseUser.data.data;
                                data.roles = this.roleSort(data.roles)
                                this.$store.commit('auth/setUser', data);

                                this.$store.dispatch('user/getNotifications', { page: 1, read: 1 });
                                this.$store.dispatch('user/getNotifications', { page: 1, read: 0 });
                            })
                    })
                    .then(() => {
                        UserService.menu()
                            .then(responseUser => {
                                let data = responseUser.data.data;
                                this.$store.commit('auth/setMenus', data);
                            })
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
                    .finally(() => {
                        this.$bvModal.hide('otherIdenties')
                        this.$router.push('/')
                    })
            }
        },

        // Program
        changeProgram(programId) {
            if (parseInt(programId) > 0) {
                UserService.changeProgram(programId)
                    .then(() => {
                        UserService.usersMe()
                            .then(responseUser => {
                                let data = responseUser.data.data;
                                data.roles = this.roleSort(data.roles)
                                this.$store.commit('auth/setUser', data);
                            })
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
                    .finally(() => {
                        this.$bvModal.hide('otherIdenties')
                        this.$router.push('/')
                    })
            }
        },

        // Filter
        filterDivStatusHide() {
            this.filterDivStatus = !this.filterDivStatus;
            this.$emit('filter-div-status', this.filterDivStatus);
        },

        // Change Password
        changePasswordModalShow() {
            this.formProcess = 'change-password'
            this.$bvModal.show('changePasswordModal')
        },
        changePasswordModalClose() {
            this.formProcess = null,
                this.$bvModal.hide('changePasswordModal')
        },

        // Print
        async print() {
            await this.$htmlToPaper('print-content');
        },
        async changeTwoFactorStatus() {
            const newVal = this.twoFactor?.two_factor_with_sms ? 0 : 1
            if (!newVal) {
                const result = await this.$swal({
                    text: this.$t('are_you_sure_to_cancel'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                if (!result.isConfirmed) {
                    return
                }
            }
            const response = await AuthService.changeTwoFactorStatus({ status: newVal }).catch(e => {
                this.showErrors(e);
            })
            if (response.status === 200) {
                this.$toast.success(this.$t('api.' + response.data.message))
                this.$refs.twoFactorModal.$refs.commonModal.hide()
                if (!this.twoFactor?.two_factor_with_sms) {
                    await AuthService.disableTwoFactor()
                }
                this.getTwoFactorStatus()
                return
            }
            this.$toast.error(this.$t('api.' + response.data.message))
        },
        async getTwoFactorStatus() {
            this.$store.dispatch('user/getTwoFactorStatus');
        },
        async setTwoFactor(newVal) {
            const oldVal = this.twoFactor.two_factor;

            if (typeof oldVal === 'undefined') {
                return
            }
            if (!this.twoFactor?.is_two_factor_forced && oldVal && !newVal) {
                const result = await this.$swal({
                    text: this.$t('are_you_sure_to_cancel'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                if (!result.isConfirmed) {
                    // this.two_factor = oldVal
                    return
                }
            }

            let changeTwoFactorStatus=false;
            if(!this.twoFactor.is_two_factor_forced && this.twoFactor.two_factor_with_sms && newVal){
                changeTwoFactorStatus=true;
            }
            else if(newVal===false){
                changeTwoFactorStatus=true;
            }
            if(changeTwoFactorStatus){
                const response = await AuthService.changeTwoFactorStatus({ status: newVal ? 1 : 0 }).catch(e => {
                    this.showErrors(e);
                })
                if (response.status === 200) {
                    this.$toast.success(this.$t('api.' + response.data.message))
                }
            }

            if (newVal===false) {
                await AuthService.disableTwoFactor()
            }
            if (oldVal === false && newVal === true) {
                this.$refs.twoFactorModal.$refs.commonModal.show()
            }
        },
        async createTwoFactor() {
            if (this.twoFactor?.two_factor_with_authenticator) {
                const disableRes = await AuthService.disableTwoFactor()
                if (disableRes.status === 200) {
                    this.$toast.success(this.$t('api.' + disableRes.data.message))
                }
                if(!this.twoFactor.is_two_factor_forced){
                    this.setTwoFactor(false);
                }
                this.getTwoFactorStatus()
                return
            }
            const response = await AuthService.createTwoFactor().catch(e => {
                this.showErrors(e);
            })
            if (response.status === 200) {
                this.two_factor_create_qr = response.data
            }
        },
        async confirmTwoFactor() {
            const isValid = await this.$refs.formTwoFactorAppCode.validate();
            if (!isValid) {
                return
            }

            const response = await AuthService.confirmTwoFactor({ code: this.two_factor_code }).catch(e => {
                this.showErrors(e);
            })
            if (response.status === 200) {
                this.$toast.success(this.$t('api.' + response.data.message))
                this.$refs.twoFactorModal.$refs.commonModal.hide()
                this.getTwoFactorStatus()
                this.two_factor_create_qr = ""
                this.two_factor_code = ""
            }
        },
        startTimer: function () {
            const timeNow = new Date().getTime();
            const timeDifference = this.countDownToTime - timeNow;
            const millisecondsInOneSecond = 1000;
            const millisecondsInOneMinute = millisecondsInOneSecond * 60;
            const millisecondsInOneHour = millisecondsInOneMinute * 60;
            const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
            const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
            const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
            const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
            const nullSec = remainingSeconds < 10 ? '0' : ''
            const nullMin = remainingMinutes < 10 ? '0' : ''
            if (remainingMinutes + remainingSeconds > 0) {
                this.timerOutput = nullMin + remainingMinutes + ":" + nullSec + remainingSeconds;
            } else {
                this.timerOutput = '00:00'
            }
        },
        updateCountDown: function (){
            this.countDownToTime = Base.LocalStorage.get('expire_in') * 1000;
        },

        callTokenClick () {
            if(this.timerBtnVariant == 'outline-danger') {
                EventBus.$emit("captchaModalShow", true)
            }
        }
    },
    mounted() {
        this.$emit('filter:show', this.filterDivStatus);
        setInterval(() => { this.startTimer() }, 1000);
        setInterval(() => { this.updateCountDown() }, 30000);
    },
}
</script>

<style lang="scss">
.list-group-item {
    padding: 0.75rem 0.8rem;
}

.bi-star-fill {
    width: 1.2rem;
    height: 1.2rem;

}

.default-active-role-star {
    color: #DA8D00;
}

.list-group-item-action {
    &:hover {
        .default-role-star {
            color: #6c757d;
        }
    }
}

.list-group {
    .active {
        .default-role-star {
            color: #00aeef;
        }

        &:hover {
            .default-role-star {
                color: #6c757d;
            }
        }
    }
}

.default-role-star {
    color: white;
}

.bi-star-fill {
    transition: all 0.2s;

    &:hover {
        transform: scale(1.2);
        color: #DA8D00 !important;
    }
}

@media screen and (min-width: 768px) {
    .header-user-info-btn {
        line-height: normal !important;
    }
}

.notification-area {
    width: 20px;
    height: 20px;
    // border: 1px solid #dd9494;
    border-radius: 100%;
    z-index: 10;
    right: 11px;
    top: -2px;
}

.notification {
    ul {
        li {
            .dropdown-item {
                padding: 6px 10px;
            }
        }
    }
}

// .header-user-info-btn {
//         line-height: normal !important;
//         background-color: #DA8D00;
//         overflow: hidden;
//     }</style>
