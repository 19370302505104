const routes = [
    {
        path: "/fashion/design/application/form",
        component: () => import("../pages/ApplicationForm")
    },
    {
        path: "/fashion/design/application/login",
        component: () => import("../pages/Login")
    },
    {
        path: "/fashion/design/application/register",
        component: () => import("../pages/Register")
    },
    {
        path: "/fashion/design/application/forgot/password",
        component: () => import("../pages/ForgotPassword")
    },
    /// Admin
    {
        path: "/fashion/design/applications",
        component: () => import("../pages/admin/Index"),
        meta: {
            isAuthenticated: true,
            permission: 'fashiondesignapplication_index'
        }
    },

];

export default routes;
