const routes = [
    {
        path: "/uploaded/student/documents",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true ,
            permission: 'uploadedstudentdocument_index'
        }
    },
    {
        path: "/my-uploaded-documents",
        component: () => import("../pages/Student.vue"),
        meta: {
            isAuthenticated: true ,
            permission: 'uploadedstudentdocument_student'
        }
    }
];

export default routes;
