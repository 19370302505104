import Api from "@/services/Index";

const me = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/notifications/me", config);
};

const read = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.patch("/notifications/read/" + id);
};

export default {
    me,
    read
};
