<template>
	<div>
		<multiselect :placeholder="$t('select')" v-model="selected" :options="options" :disabled="disabled"
			:class="validateError != '' ? 'box-border-color' : ''" @input="handleInput($event, multiple)" label="text"
			track-by="value" :multiple="multiple" :close-on-select="!multiple" :clear-on-select="!multiple"
			:select-label="''" :selected-label="''" :deselect-label="''">
			<template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
				<span class="multiselect__single" v-if="values.length && !isOpen">
					{{ translateNSelected(values) }}
				</span>
			</template>
			<span slot="noOptions">{{ $t('no_options') }}</span>
			<span slot="noResult">{{ $t('no_result') }}</span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"></span>
	</div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import setSelected from "@/helpers/setSelected";
import handleInput from "@/helpers/handleInput";

// Services
import RoleService from "@/services/RoleService";

export default {
	props: {
		value: {
			default: null
		},
		valueType: {
			default: 'id'
		},
		multipleReturnType: {
			default: 'array',
		},
		validateError: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: function () {
			this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
		},
	},
	data() {
		return {
			selected: null,
			options: []
		}
	},
	created() {
		this.getOptions();
	},
	methods: {
		translateNSelected,
		handleInput,
		setSelected,
		getOptions() {
			const config = {
				params: {
					sort: 'name',
					limit: -1
				},
			};
			RoleService.getAll(config)
				.then(response => {
					const data = response.data.data;
					data.map(item => {
						item.value = item[this.valueType]
						item.text = this.getLocaleText(item, "explanation")
						this.options.push(item);

					});
				})
				.then(() => {
					this.selected = this.setSelected(this.value, this.options, this.multiple,
						this.multipleReturnType);
				});
		}
	}
}
</script>
