import Base from '@/plugins/Base';
import router from '@/plugins/Router'
import UndergraduateTransferService from '@/services/UndergraduateTransferService'
import showErrors from '@/helpers/showErrors'

const module = {
    namespaced: true,
    state: () => ({
        data: {},
        nationalID: null,
        pin: null
    }),
    getters: {
        getData(state) {
            return state.data
        },
        getStatus(state){
            return state.data && state.data.status ? state.data.status:null;
        },
        getNationalID(state) {
            return state.nationalID
        },
        getPin(state) {
            return state.pin
        }
    },

    mutations: {
        setData(state, data) {
            state.data = data
            Base.LocalStorage.set('fashion_design_app_data', state.data)
        },
        setStatus(state, status) {
            state.data.status = status;
            Base.LocalStorage.set('undergraduate_transfer_data', state.data)
        },
        setNationalID(state, nationalID) {
            state.nationalID = nationalID
            Base.LocalStorage.set('fashion_design_app_id', state.nationalID)
        },
        setPin(state, pin) {
            state.pin = pin
            Base.LocalStorage.set('fashion_design_app_pin', state.pin)
        },
        clearData(state) {
            state.data = {}
            state.nationalID = null
            state.pin = null
        }
    },

    actions: {
        initData({commit}) {
            let data = Base.LocalStorage.get('fashion_design_app_data')
            let nationalID = Base.LocalStorage.get('fashion_design_app_id')
            let pin = Base.LocalStorage.get('fashion_design_app_pin')
            if (data && nationalID && pin) {
                commit('setData', data)
                commit('setNationalID', nationalID)
                commit('setPin', pin)
            }
        },

        isLogin({state}) {

            let data = state.data;
            let nationalID = state.nationalID;
            let pin = state.pin;
            if (!data || !nationalID || !pin) {
                router.push('/fashion/design/application/login')
            }
        },

        isLoginGoIndex({state}) {
            let data = state.data;
            let nationalID = state.nationalID;
            let pin = state.pin;
            if (data && nationalID && pin) {
                router.push('/fashion/design/application/form')
            }
        },

        logout({commit}) {
            commit('clearData')
            Base.LocalStorage.remove('fashion_design_app_data')
            Base.LocalStorage.remove('fashion_design_app_id')
            Base.LocalStorage.remove('fashion_design_app_pin')

            router.push('/fashion/design/application/login')
        }
    }
}

export default module;
