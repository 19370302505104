const routes = [
    {
        path: "/program/outcome",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "programoutcome_index"
        }
    }
]

export default routes;
