const routes = [
    {
        path: "/course/activities",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: ""
        }
    }
]

export default routes;
