<template>
    <div class="notification d-flex position-relative">
        <div
            class="notification-area position-absolute d-flex justify-content-center align-items-center text-primary font-weight-bold">
            {{ $store.getters['user/getNotificationCount'] || '' }}
        </div>
        <b-dropdown id="dropdown-form" right ref="dropdown"
            class="mr-2 ml-sm-3 ml-3 ml-lg-0 notify dropdown-mobile-left " variant="outline-secondary" no-caret>
            <template #button-content>
                <i class="ri-notification-line mt-1"></i>
                <!-- <i class="ri-checkbox-blank-circle-fill ri-xs text-success position-absolute alert-dot"></i> -->
                <!-- <div class="dot bg-success position-absolute"></div> -->
            </template>
            <div class="d-flex flex-row justify-content-between align-items-center">
                <b-button :variant="`${readValue ? 'outline-secondary secondary-border-light' : 'outline-primary'}`"
                    class="ml-2" @click="readValue = 0">
                    <i class="ri-mail-line font-size-20 mr-1"></i>
                    {{ $t('unread') }}
                </b-button>

                <b-button :variant="`${readValue ? 'outline-primary' : 'outline-secondary secondary-border-light'}`"
                    class="mr-2" @click="readValue = 1">
                    <i class="ri-mail-open-line font-size-20 mr-1"></i>
                    {{ $t('read') }}
                </b-button>
            </div>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button class="mb-1"
                @click="$store.commit('user/setNotification', { data: notification, handle: true })"
                v-for="(notification, key) in $store.getters['user/getNotifications'](readValue)" :key="key">
                <i class="ri-checkbox-blank-circle-fill ri-xs text-primary position-absolute left-0 ml-1 mt-1"
                    v-if="!notification.read"></i>
                <p class="mb-2 pl-2">
                    {{ notification.title }}
                </p>
                <!-- <div class="d-flex">
                                        <i class="ri-checkbox-blank-circle-fill ri-xs mt-3px mr-3 text-success"></i>
                                        <div>
                                            <div class="ellipsis-2">{{ notification.title }}</div>
                                            <div class="text-right mt-2 text-muted">{{ notification.time }}</div>
                                        </div>
                                    </div> -->
            </b-dropdown-item-button>
            <b-dropdown-item-button class="mb-1" v-if="$store.getters['user/getNotifications'](readValue)?.length == 0">
                <p class="mb-2 pl-2">
                    {{ $t('no_result_2') }}
                </p>
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <div class="d-flex flex-row justify-content-between align-items-center"
                v-if="notificationPaginate(readValue)">
                <b-button variant="outline-secondary secondary-border-light w-40" class="ml-2"
                    :disabled="notificationPaginate(readValue).currentPage < 2"
                    @click="$store.dispatch('user/getNotifications', { page: notificationPaginate(readValue).currentPage - 1 })">
                    <i class="ri-arrow-left-double-line font-size-20"></i>
                </b-button>
                <span class="font-weight-bold">
                    {{ $t('page') }} {{ notificationPaginate(readValue).currentPage || '' }}
                </span>
                <b-button variant="outline-secondary secondary-border-light w-40" class="mr-2"
                    :disabled="notificationPaginate(readValue).lastPage >= notificationPaginate(readValue).currentPage"
                    @click="$store.dispatch('user/getNotifications', { page: notificationPaginate(readValue).currentPage + 1 })">
                    <i class="ri-arrow-right-double-line font-size-20"></i>
                </b-button>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "NotificationDropdown",
    props: {
    },
    data() {
        return {
            readValue: 0
        }
    },
    watch: {

    },
    computed: {
        ...mapGetters({ notificationPaginate: 'user/getNotificationPaginate' }),
    },
    mounted() {
        this.$store.dispatch('user/getNotifications', { page: 1, read: 1 });
        this.$store.dispatch('user/getNotifications', { page: 1, read: 0 });
    }
}
</script>
