const routes = [
    {
        path: "/simulation/prices",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'productprice_index'
        }
    },
    {
        path: "/simulation/prices/create",
        component: () => import("../pages/Create.vue"),
        meta: {
            isAuthenticated: true,
            permission:'productprice_index'
        }
    }
];

export default routes;
