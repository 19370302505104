const routes = [
    {
        path: '/system/routes',
        component: () => import('../pages/routes/Index'),
        meta: {
            isAuthenticated: true,
            isAdmin: true
        }
    },
    {
        path: '/system/manual',
        component: () => import('../pages/manual/Index'),
        meta: {
            isAuthenticated: true,
            isAdmin: true
        }
    }
]

export default routes;
