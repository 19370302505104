const routes = [
    {
        path: "/graduates/card/create/:studentNumber?",
        component: () => import("../pages/cardCreate/Index"),
        name: "graduatesCardCreateIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },

    {
        path: "/graduates",
        component: () => import("../pages/Index"),
        name: "graduatesIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },
    {
        path: "/graduates/:id",
        component: () => import("../pages/detail/Index"),
        name: "graduatesDetailIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },
    {
        path: "/graduates/send/sms",
        component: () => import("../pages/smsSend/Index"),
        name: "graduatesSmsSendIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },

    {
        path: "/graduates/send/sms/search",
        component: () => import("../pages/smsSend/Search"),
        name: "graduatesSendSmsSearch",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },

];

export default routes;
