<template>
    <div>
        <b-form-select v-model="selected" :options="options" :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput" />
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: null
        },
        options: {
            type: Array,
            default: function () {
                return [
                    { value: null, text: '' },
                    { value: true, text: this.$t('yes') },
                    { value: false, text: this.$t('no') }
                ]
            }
        },
        validateError: {
            type: String,
            default: ''
        },
    },
    created() {
        this.selected = this.value
    },
    data() {
        return {
            selected: null,
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.selected = newValue
                if (newValue === 1) {
                    this.selected = 1;
                }
                else if (newValue === 0) {
                    this.selected = 0;
                }
            },
            immediate: true
        }
    },
    methods: {
        handleInput(event) {
            this.$emit('input', event)
        }
    }
}
</script>
