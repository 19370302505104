<template>
    <div>
        <multiselect :class="validateError != '' ? 'box-border-color' : ''" :clear-on-select="!multiple"
            :close-on-select="!multiple" :deselect-label="''" :multiple="multiple" :options="options"
            :placeholder="$t('select')" :select-label="''" :selected-label="''" :disabled="disabled" label="text"
            track-by="value" v-model="selected" :searchable="searchable" @input="handleInput($event, multiple)">
            <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import SystemService from '@/services/SystemService'

export default {
    name: "ModelListSelectbox",
    props: {
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        searchable: {
            default: true,
        },
        validateError: {
            type: String,
            default: '',
        },
        activeYears: {
            type: Boolean,
            default: false,
        },
        modelId: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: [],
            activeDate: null
        }
    },
    created() {
        this.getOptions()
    },
    methods: {
        handleInput: handleInput,
        setSelected: setSelected,
        translateNSelected: translateNSelected,
        async getOptions() {
            const key = 'system_model_list';

            let local = this.getWithExpiry(key, true);
            if (local) {
                this.setOptions(local);
                this.selected = this.setSelected(this.value, this.options, this.multiple)
                return;
            }

            SystemService.getModelList()
                .then((response) => {
                    const data = response.data.data;
                    this.setWithExpiry(key, data);
                    this.setOptions(data);
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                })
                .then(() => {
                    if (this.activeYears) {
                        this.selected = [{ text: this.activeDate, value: this.activeDate }]
                    }
                })
        },
        setOptions(data) {
            if (this.modelId) {
                this.options = data
                return
            }
            data.forEach((item) => {
                this.options.push({
                    value: item,
                    text: item,
                })
            })
        }
    }
}
</script>
