import store from '@/plugins/Store'

const routes = [
    {
        path: "/login",
        component: () => import("./../pages/Index"),
        meta: { isNotAuthenticated: true }
    },
    {
        path: "/logout",
        component: () => {
            store.dispatch("auth/logout");
        }
    }
];

export default routes;
