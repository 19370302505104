const routes = [
    {
        path: "/lms/:courseId",
        component: () => import("../pages/Courses.vue"),
        meta: {
            isAuthenticated: true,
            permission: ""
        }
    },

];

export default routes;
