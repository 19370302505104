import Api from "@/services/Index";

// Student
const start = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfers/start", formData, {
        headers: {
            // remove headers
        },
    });
};

// Auth
const verify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfers/verify", formData);
};

const login = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfers/login", formData);
};

// 1. Save
const save = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfers/save", data);
};

// 3. Documents
const requiredDocuments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/documents", formData);
};

const uploadDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/upload", formData);
};

const downloadDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/download", formData, {
        responseType: "arraybuffer",
    });
};
const downloadDocumentAdmin = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/download/admin", formData, {
        responseType: "arraybuffer",
    });
};
const deleteDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/undergraduate/transfer/delete/document", {
        data: { ...formData },
    });
};

const sendApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/send-approve", formData);
};

// Admin
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/undergraduate/transfers", config);
};

const approve = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/approve", config);
};

// Student Affairs
const studentAffairsGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/undergraduate/transfer/index/student-affairs", config);
};

const studentAffairsApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/approve/student-affairs", config);
};

// Prep School
const prepSchoolGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/undergraduate/transfer/index/prep-school", config);
};
const prepSchoolApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/approve/prep-school", config);
};

// Academic Unit Office
const academicUnitOfficeGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(
        "/undergraduate/transfer/index/academic-unit-office",
        config
    );
};
const academicUnitOfficeApprove = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/undergraduate/transfer/approve/academic-unit-office",
        config
    );
};

const bulkMessage = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/bulk-message", data);
};

const sendMessageStudentAffairs = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "/undergraduate/transfer/send-message-student-affairs",
        data
    );
};

const pinReminder = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/undergraduate/transfer/pin-reminder", data);
};

const updateForPrepSchool = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/update/prep-school", data);
};

const checkDate = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("undergraduate/transfers/check-date");
};

const updateUniversity = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "undergraduate/transfer/update-university/student-affairs",
        data
    );
};

const declinePreference = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/decline-preference", data);
};

const updatePreferenceTransferType = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post(
        "undergraduate/transfer/update-preference-transfer-type",
        data
    );
};

const undergraduateTransferUpdateFields = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/update-fields", data);
};

const excelReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("undergraduate/transfers/excel-export", {
        ...config,
        responseType: "arraybuffer",
    });
};

const prepSchoolExcelReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("undergraduate/transfers/excel-export-prep-school", {
        ...config,
        responseType: "arraybuffer",
    });
};

const academicUnitExcelReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(
        "undergraduate/transfers/excel-export-academic-unit-office",
        { ...config, responseType: "arraybuffer" }
    );
};

const transferDelete = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/delete", data);
};

const registerStudent = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/register-student", data);
};

const changeRank = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/change-preference-rank", data);
};

const changeStatus = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/change-status", data);
};

const revertPreferenceStatus = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("undergraduate/transfer/revert-preference-status", data);
};

const placedStudentExcelExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("undergraduate/transfer/placed-student-excel-export", {
        ...config,
        responseType: "arraybuffer",
    });
};

export default {
    start,
    verify,
    login,
    save,
    requiredDocuments,
    uploadDocument,
    downloadDocument,
    downloadDocumentAdmin,
    deleteDocument,
    sendApprove,
    getAll,
    approve,
    studentAffairsGetAll,
    studentAffairsApprove,
    prepSchoolGetAll,
    prepSchoolApprove,
    academicUnitOfficeGetAll,
    academicUnitOfficeApprove,
    bulkMessage,
    pinReminder,
    updateForPrepSchool,
    checkDate,
    updateUniversity,
    declinePreference,
    updatePreferenceTransferType,
    undergraduateTransferUpdateFields,
    excelReport,
    prepSchoolExcelReport,
    academicUnitExcelReport,
    transferDelete,
    registerStudent,
    changeRank,
    sendMessageStudentAffairs,
    changeStatus,
    revertPreferenceStatus,
    placedStudentExcelExport,
};
