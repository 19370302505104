const routes = [
    {
        path: "/course/schedules/attendance",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
             permission: "courseschedule_getattendance",
        }
    },
];

export default routes;
