const routes = [
    {
        path: "/grade/entry/:courseId?",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_entry"
        }
    },
    {
        path: "/finalize/grades",
        component: () => import("../pages/Finalize"),
        meta: {
            isAuthenticated: true,
            permission: "studentcourse_finalizegrades"
        }
    },
    {
        path: "/grade-entry",
        component: () => import("../pages/Main"),
        meta: {
            isAuthenticated: true,
        }
    }
];

export default routes;
