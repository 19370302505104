const routes = [
    {
        path: "/course/registration/",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            isSpecialStudent:false
        }
    },
    {
        path: "/course/registration/:id",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true
        },
    },
    {
        path: "/course/registration/exchange/",
        component: () => import("../pages/Exchange.vue"),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            isSpecialStudent:false
        }
    },
    {
        path: "/course/registration/exchange/:id",
        component: () => import("../pages/Exchange.vue"),
        meta: {
            isAuthenticated: true
        },
    }
];

export default routes;
