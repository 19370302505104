const routes = [
    {
        path: "/course/program/outcome",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "courseprogramoutcome_index"
        }
    }
]

export default routes;
