const routes = [
    {
        path: '/minor/application',
        component: () => import('../pages/application/Index'),
        meta: {
            isAuthenticated: true,
            isStudent: true,
            permission: 'minorapplication_studentform'
        }
    },
    {
        path: '/minor/application/admin',
        component: () => import('../pages/admin/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'minorapplication_adminindex'
        }
    },
    {
        path: '/minor/application/prep-school',
        component: () => import('../pages/prepSchool/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'minorapplication_prepschoolindex'
        }
    },
    {
        path: '/minor/application/academic-unit-office',
        component: () => import('../pages/academicUnit/Index'),
        meta: {
            isAuthenticated: true,
            permission: 'minorapplication_academicofficeindex'
        }
    }
];

export default routes;
