const routes = [
    {
        path: "/attendance/entry/:sectionId?",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "attendance_getattendanceforsection"
        }
    }
];

export default routes;
