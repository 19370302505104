import qs from "qs";
import NotificationsService from "@/services/NotificationsService";
import AuthService from "@/services/AuthService";
import { checkPermission } from "@/main";

const userModule = {
    namespaced: true,
    state: () => ({
        user: {
            name: "",
            surname: "",
        },
        notificationsRead: {
            data: [],
            pagination: {},
        },
        notificationsNotRead: {
            data: [],
            pagination: {},
        },
        notification: null,
        notificationPaginate: null,
        notificationModalValue: false,
        twoFactor: {},
    }),
    getters: {
        getNotificationCount: (state) => {
            return state.notificationsNotRead.data?.length || 0;
        },
        getNotifications: (state) => (read) => {
            if (read) {
                return state.notificationsRead.data || [];
            } else {
                return state.notificationsNotRead.data || null;
            }
        },
        getNotificationPaginate: (state) => (read) => {
            if (read) {
                return state.notificationsRead.pagination || null;
            } else {
                return state.notificationsNotRead.pagination || null;
            }
        },
        getTwoFactor: (state) => {
            return state.twoFactor;
        },
    },
    mutations: {
        setNotifications(state, { data, pagination, read }) {
            if (read) {
                state.notificationsRead.data = data;
                state.notificationsRead.pagination = pagination;
            } else {
                state.notificationsNotRead.data = data;
                state.notificationsNotRead.pagination = pagination;
            }
        },
        setNotification(state, { data, handle = true }) {
            state.notificationModalValue = handle;
            handle ? (state.notification = data) : (state.notification = null);
        },
        setTwoFactor(state, data) {
            state.twoFactor = {};
            state.twoFactor = data;
        },
    },
    actions: {
        async getNotifications({ commit }, { page = 1, read = 0 }) {
            if (!checkPermission("notification_me")) {
                commit("setNotifications", { data: [], pagination: {}, read });
                return;
            }
            const config = {
                params: {
                    page,
                    limit: 20,
                    read,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
            const response = await NotificationsService.me(config);
            if (response.status === 200) {
                commit("setNotifications", { ...response.data, read });
            }
        },
        async getTwoFactorStatus({ commit }) {
            const response = await AuthService.twoFactorStatus().catch((e) => {
                this.showErrors(e);
            });
            if (response.status === 200) {
                commit("setTwoFactor", response.data.data);
            }
        },
    },
};

export default userModule;
