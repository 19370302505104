
const routes = [
    // Admin
    {
        path: "/unihall/quotas",
        component: () => import("../pages/admin/quotas"),
        meta: {
            isAuthenticated: true,
        },
    },
    {
        path: "/unihall/price/:id",
        component: () => import("../pages/admin/quotaPrice"),
        meta: {
            isAuthenticated: true,
        },
    },
    {
        path: "/unihall/applications",
        component: () => import("../pages/admin/applications"),
        meta: {
            isAuthenticated: true,
        },
    },

    // Student
    {
        path: "/unihall/login",
        component: () => import("../pages/login"),
    },
    {
        path: "/unihall/application",
        component: () => import("../pages/application"),
    },
    {
        path: "/unihall/application/purchase/:status/:message?",
        component: () => import("../pages/completePurchase"),
    },
    // {
    //     path: "/transfer/register",
    //     component: () => import("../pages/transfer/Register"),
    // },
];

export default routes;
