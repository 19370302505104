<template>
    <div>
        <multiselect v-model="selected" :class="validateError != '' ? 'box-border-color' : ''" :clear-on-select="!multiple"
            :close-on-select="!multiple" :disabled="disabled" :deselect-label="''" :multiple="multiple" :options="options"
            :placeholder="$t('select')" :select-label="''" :selected-label="''" label="text" track-by="value"
            @input="handleInput($event, multiple)">
            <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t('no_options') }}</span>
            <span slot="noResult">{{ $t('no_result') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
// Helpers
import translateNSelected from '@/helpers/translateNSelected';
import handleInput from '@/helpers/handleInput';
import setSelected from '@/helpers/setSelected';

// Services
import UnihallQuotaService from '@/services/UnihallQuotaService';

export default {
    props: {
        value: {
            default: null
        },
        valueType: {
            type: String,
            default: 'id'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        validateError: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: []
        }
    },
    created() {
        this.getOptions();
    },
    methods: {
        translateNSelected,
        handleInput,
        setSelected,
        getOptions() {
            const config = {
                params: {
                    sort: this.getLocaleField('name'),
                    limit: -1
                }
            };

            UnihallQuotaService.getAll(config)
                .then(response => {
                    const data = response.data.data;
                    data.map(item => {
                        this.options.push({
                            value: item[this.valueType],
                            text: this.getLocaleText(item, 'name')
                        })
                    })
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                })
        }
    }
}
</script>
