
const routes = [
    {
        path: "/turkmer/application/form",
        component: () => import("../pages/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "",
        },
    },
    {
        path: "/turkmer/application/list",
        component: () => import("../pages/applicationList/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "",
            // turkmerapplication_index
        },
    },
    {
        path: "/turkmer/application/:id",
        component: () => import("../pages/applicationList/showApplication.vue"),
        meta: {
            isAuthenticated: true,
            permission: "",
        },
    },
];

export default routes;
