import Api from '@/services/Index';

const getModelList = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/system/model-list');
}

const getModelColumnList = async (model) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/system/model-column-list/' + model);
}

export default {
    getModelList,
    getModelColumnList,
}
