<template>
    <div id="sidebar" class="collapsed" :class="[
            {'onmobile' : isOnMobile},
            {'clicked' : sideBarExpandedWrapper},
            {'sidebar-expanded' : sideBarExpandedWrapper}
        ]">
        <sidebar-menu :menu="menus"
                      :show-one-child="true"
                      :collapsed="!sideBarExpandedWrapper"
                      @toggle-collapse="changeSideBarExpandedWrapper"
                      v-bind="settings"/>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                window: {
                    width: 0,
                    height: 0
                },
                isOnMobile: true,
                settings: {
                    width: '280px',
                    widthCollapsed: '90px',
                    default: ''
                }
            }
        },
        computed: {
            menus() {
                let menus = this.$store.getters['auth/getMenus'];
                let newMenus = menus.map(menu1Data => {
                    let menu1 = {};
                    menu1.title = this.getLocaleText(menu1Data, 'name');
                    menu1.icon = menu1Data.class;
                    menu1.href = menu1Data.url ? menu1Data.url : null;
                    menu1.child = null;
                    if (menu1Data.items) {
                        menu1.child = menu1Data.items.map(menu2Data => {
                            let menu2 = {};
                            menu2.title = this.getLocaleText(menu2Data, 'name');
                            menu2.icon = menu2Data.class;
                            menu2.href = menu2Data.url ? menu2Data.url : '';
                            menu2.child = null;
                            if (menu2Data.items) {
                                menu2.child = menu2Data.items.map(menu3Data => {
                                    let menu3 = {};
                                    menu3.title = this.getLocaleText(menu3Data, 'name');
                                    menu3.icon = menu3Data.class;
                                    menu3.href = menu3Data.url ? menu3Data.url : '';
                                    menu3.child = null;

                                    return menu3;
                                })
                            }

                            return menu2;
                        })
                    }
                    return menu1;
                })
                return newMenus;
            },
            sideBarExpandedWrapper() {
                return this.$store.getters.getAppLayoutSideBarExpandedWrapper;
            },
            nightMode() {
                return this.$store.getters.getNightMode;
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        methods: {
            changeSideBarExpandedWrapper() {
                this.$store.commit('changeAppLayoutSidebarExpandedWrapper');
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
                if (this.window.width <= 992) {
                    this.isOnMobile = true
                }
                else {
                    this.isOnMobile = true
                }
            }
        }
    }
</script>
