const routes = [
    {
        path: "/highhonor",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission: "student_highhonorreport"
        }
    },

];

export default routes;
