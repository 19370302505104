import Base from "@/plugins/Base";
import router from "@/plugins/Router";

const module = {
    namespaced: true,
    state: () => ({
        form: Base.LocalStorage.get("unihall_form") || {},
        token: Base.LocalStorage.get("unihall_token") || null,
    }),
    getters: {
        getForm(state) {
            return state.form;
        },
        getToken(state) {
            return state.token;
        },
    },
    mutations: {
        setForm(state, data) {
            Base.LocalStorage.set("unihall_form", data);
            state.form = data;
        },
        setToken(state, data) {
            Base.LocalStorage.set("unihall_token", data);
            state.token = data;
        },
    },
    actions: {
        logout({ commit }) {
            commit('setForm', {})
            commit('setToken', null)
            router.push('/unihall/login')
        }
    },
};

export default module;
