
const routes = [
    {
        path: "/advisor/bulk",
        component:()=>import("../pages/Index"),
        meta:{
            isAuthenticated:true
        }
    }
]

export default routes
