<template>
    <div id="app">
        <router-view :key="this.$i18n.locale" />
    </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import { useFavicon } from '@vueuse/core'

export default {
    name: 'App',
    beforeCreate() {
        console.clear();
    },
    async created() {
        this.$store.dispatch('initLocale')
            .then(() => {
                this.initTranslations()
            })
        this.$store.dispatch('initNightMode');
        this.$store.dispatch('initAppLayouts');
        await this.$store.dispatch('dashboard/getAppImages');

        const icon = useFavicon()
        icon.value = this.$store.getters['dashboard/getImage']('favicon.ico')
    },
    metaInfo() {
        return {
            title: process.env.VUE_APP_PROJECT_TITLE,
            titleTemplate: '%s | ' + process.env.VUE_APP_PROJECT_TITLE,
            htmlAttrs: {
                lang: this.$i18n.locale
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' }
            ]
        }

    },
    methods: {
        initTranslations() {
            const language_lines = this.getWithExpiry("language_lines");
            if (!language_lines) {
                AuthService.getTranslation()
                    .then(response => {
                        this.setLanguageLine(response.data)
                    })
            } else {
                this.setLanguageLine(language_lines)
            }
        },
        setLanguageLine(data) {
            const trApi = [];
            const enApi = [];
            Object.keys(data).forEach(function (key) {
                trApi[key] = data[key].tr;
                enApi[key] = data[key].en;
            })
            const messages = this.$i18n.messages;
            this.$i18n.setLocaleMessage('tr', {
                ...messages.tr,
                api: {
                    ...trApi
                }
            })
            this.$i18n.setLocaleMessage('en', {
                ...messages.en,
                api: {
                    ...enApi
                }
            })
            this.clearExpiredCache();
            this.setWithExpiry("language_lines", data, 36000000, true);
        }
    }
}
</script>
