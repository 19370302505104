import Api from '@/services/Index';

const usersMe = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/users/me');
}

const menu = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/users/me/menu');
}

const photo = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/users/me/photo',{responseType: 'arraybuffer'});
}

const changeRole = async (role) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/users/me/role', {
        role_id: role.id,
        faculty_code: role.faculty_code,
        department_code: role.department_code,
        program_code: role.program_code,
    })
}
const changeProgram = async (programId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/users/me/program', {
        student_program_id: programId
    })
}
const loginAs = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/users/login-as', data)
}
const updatePersonalInformation = async (formData, userId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/users/update-personal-information/'+userId, formData)
}
const transfer = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/users/user-transfer', data)
}
export default {
    usersMe,
    menu,
    photo,
    changeRole,
    changeProgram,
    loginAs,
    updatePersonalInformation,
    transfer
}
