const routes = [
    {
        path: '/double-major/application',
        component: () => import('../pages/application/Index'),
        meta: {
            isAuthenticated: true,
            isStudent: true,
        }
    },
    {
        path: '/double-major/application/admin',
        component: () => import('../pages/admin/Index'),
        meta: {
            isAuthenticated: true,
        }
    },
    {
        path: '/double-major/application/prep-school',
        component: () => import('../pages/prepSchool/Index'),
        meta: {
            isAuthenticated: true,
        }
    },
    {
        path: '/double-major/application/academic-unit-office',
        component: () => import('../pages/academicUnit/Index'),
        meta: {
            isAuthenticated: true,
        }
    }
];

export default routes;
