import VueSweetalert2 from 'vue-sweetalert2'
import Vue from "vue"
import i18n from "../plugins/i18n"
import router from "@/plugins/Router"
import Base from "@/plugins/Base"
import { EventBus } from "@/main"

Vue.use(VueSweetalert2)

const half_timeout_min = 30
const extra_time_min = 1
const check_period = 1000

const sessionTrackingRun = () => {
    HalfPeriodTracking()
}

const HalfPeriodTracking = () => {
    let token = Base.LocalStorage.get('access_token')
    if(token != null) {
        Base.LocalStorage.set('expire_counter', Math.floor(+ Date.now()/1000)+(half_timeout_min*60))
        setInterval(() => {
            let now = Math.floor(+ Date.now()/1000)
            CheckHalfHourExpired(now)
            CheckFullSessionExpired(now)
        }, check_period)
    }
}

const CheckHalfHourExpired = (current_timestamp) => {
    let expire_counter = Base.LocalStorage.get('expire_counter')
    if(current_timestamp > expire_counter && expire_counter != null) {
        if (!Vue.swal.isVisible()) {
            showAlert(30)
        }
    }
    checkExtraTime(current_timestamp, expire_counter)
}

const showAlert = (seconds, mode = 'half') => {
    let previous_title = document.title
    document.title = i18n.t('session_timeout_warning')
    Vue.swal({
        title: i18n.t('session_timeout_warning'),
        text: i18n.t('session_timeout_warning_text'),
        showCancelButton: true,
        confirmButtonText: i18n.t('session_timeout_continue'),
        cancelButtonText: i18n.t('session_timeout_logout'),
        allowOutsideClick: false

    }).then(response => {
        if(mode == 'half'){
            if (response.isConfirmed == true) {
                document.title = previous_title
                sessionTrackingRun()
            }
            else {
                router.push('/logout')
            }
        } else {
            if (response.isConfirmed == true) {
                getToken()
            } else {
                router.push('/logout')
            }
        }
    })

    /// After show of modal setTimeout for auto logout

}

const checkExtraTime = (now, endTime) => {
    let diff = now - endTime
    if(diff > (extra_time_min*60)) {
        Vue.swal.close()
        router.push('/logout')
    }
}

const CheckFullSessionExpired = (current_timestamp) => {
    let expire_in = Base.LocalStorage.get('expire_in')
    if(current_timestamp > expire_in && expire_in != null) {
        if (!Vue.swal.isVisible()) {
            showAlert(60, 'full')
        }
    }
    checkExtraTime(current_timestamp, expire_in)
}

const getToken = () => {
    EventBus.$emit("captchaModalShow", true)
}

export default {
    sessionTrackingRun
}
