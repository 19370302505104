<template>
    <div>
        <multiselect v-model="selected"
                     :class="validateError != '' ? 'box-border-color' : ''"
                     :clear-on-select="!multiple"
                     :close-on-select="!multiple"
                     :deselect-label="''"
                     :multiple="multiple"
                     :options="optionsSelectbox"
                     :placeholder="placeholder ? placeholder : this.$t('select')"
                     :select-label="''"
                     :selected-label="''"
                     :disabled="disabled"
                     label="text"
                     track-by="value"
                     @select="select"
                     @input="handleInput($event, multiple, 'array', true)">
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError">
        </span>
    </div>
</template>

<script>
// Helpers
import handleInput from '@/helpers/handleInput';
import setSelected from '@/helpers/setSelected';
import translateNSelected from '@/helpers/translateNSelected';

export default {
    props: {
        value: {
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: true
        },
        sort: {
            type: Boolean,
            default: false
        },
        validateError: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: null,
            optionsSelectbox: []
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.selected = this.setSelected(newValue, this.options, this.multiple)
            },
            immediate: true
        },
        options: {
            immediate: true,
            handler: function (newValue) {
                this.optionsSelectbox = this.options
                this.selected = this.setSelected(this.value, newValue, this.multiple)
            }
        }
    },
    created() {
        this.selected = this.setSelected(this.value, this.options, this.multiple)
    },
    methods: {
        translateNSelected,
        handleInput,
        setSelected,
        select(val) {
            this.$emit('optionSelected', val)
        },
    }
}
</script>
