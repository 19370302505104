const routes = [
    {
        path: "/myProfile",
        component: () => import("../pages/Index"),
        name: "myProfileIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },
    {
        path: "/my-profile/:id",
        component: () => import("../pages/detail/Index"),
        name: "myProfileDetailIndex",
        meta: {
            isAuthenticated: true,
            //permission: ""
        }
    },

];

export default routes;
