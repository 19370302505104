const translations = {
    code: "tr",
    fields: {},
    messages: {
        confirmed: "Doğrulama hatalı.",
        email: "Bu alanına girilen e-posta adresi geçersiz.",
        required: "Bu alan zorunludur.",
        required_if: "Bu alan zorunludur.",
        length: 'Bu alan "{length}" karakter olmalıdır.',
        min: 'Bu alan en az "{length}" karakter olmalıdır.',
        max: 'Bu alan en fazla "{length}" karakter olmalıdır.',
        max_value: "Bu alan {_value_} dan küçük olmalıdır.",
        min_value: "Bu alan {_value_} dan büyük olmalıdır.",
        oneOf: "Bu alan {_value_} değerlerinden biri olmalıdır",
    },
};

export default translations;
