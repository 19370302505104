const translations = {

    document_request_info_text: "In order to facilitate access to documents requested by our students (student certificates, transcripts, discipline reports, language proficiency certificates, etc.) requests for student documents with qualified electronic signature are to be made through OIS. However, students who request documents with electronic signature must first apply for the relevant documents through OIS and then visit the Student Affairs Office at Beşiktaş Campus to pick them up.We would like to remind you that original signed documents and documents with qualified electronic signature are “official documents” and therefore; using them in violation with the current laws and regulations or outside their intended purposes, modifying them or allowing other persons to use them constitute acts that require disciplinary punishments within the scope of the Higher Education Institutions Student Discipline Regulation and are considered as crimes subject to criminal sanctions within the scope of the Turkish Criminal Law.You may get your Student Certificate through the E-Government portal using your E-Government password. Student certificates obtained through E-Government are valid in all relevant institutions and organizations.",
    course_transfer_form_info: "You may click on \"Send Request\" button to create your request after you upload your course transfer document(s). You will be notified when your course transfer process is completed. You are required to confirm your course transfer process by clicking on \"Course Transfer\" on \"My Requests\" page. For those who with transcripts from Bahçeşehir University, the transcripts will be uploaded automatically by the system. Please refer to the <a href=\"#\" class='text-primary' download>User Guide</a> for further details.",
    
}

export default translations


