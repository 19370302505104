const routes = [
    {
        path: '/oauth/authorize',
        component: () => import('../pages/Index'),
        meta: {
            isNotAuthenticated: true
        }
    }
];

export default routes;
